import { values } from 'lodash'
import { useCallback } from 'react'
import InputField from './inputfield'

export default function InputFieldCreditCardValidUntil({ value, onChange, ...rest }) {
  const onChangeWrapper = useCallback(evt => {
    let value = evt.target.value.replace(/[^0-9]/g, '')
    let part0 = value.substr(0, 2)
    let part1 = value.substr(2, 4)
    value = `${part0}/${part1}`
    const newEvent = {target: {value}}
    onChange(newEvent)
  }, [onChange])

  value = value || ''
  value = value.replace(/[^0-9]/g, '')
  value = value.substr(0, 6)

  const part0 = value.substr(0, 2)
  const part1 = value.substr(2, 4)
  
  value = (part0.length === 2 ? `${part0} / ` : part0)  + part1

  return (
    <InputField value={value} onChange={onChangeWrapper} {...rest} />
  )
}