import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Dropdown from '../dropdown'
import { LinkLangQueryParam, useLang } from '../langutils'

import Logo from '../../assets/LogoNew.png'
import { useCallback, useMemo, useState } from 'react'
import './style.scss';

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import NavDropdown from 'react-bootstrap/NavDropdown'

const NavbarBackground = styled.div`
  position: fixed;
  background-color: rgb(0, 53, 95);
  height: 7em;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  width: 1440px;
`

const NavbarContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
  align-items: center;
`

const MySelection = styled.span`
  color: rgb(0, 156, 182);
  font-family: "PT Sans", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
`

const NavbarItemList = styled.ul`
  display: flex;
`

const NavbarItem = styled.li`
  cursor: pointer;
  color: white;
  font-family: "PT Sans", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  margin-left: 3em;

  &:first {
    margin-left: 7em;
  }
`

const LinkLangQueryParamStyled = styled(LinkLangQueryParam)`
  color: white;
  text-decoration: none;
`

const languages = ['de', 'en']

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export default function NavbarReact({}) {
  const shoppingCart = useSelector(({ shoppingCart }) => shoppingCart)

  const translatedLanguages = useMemo(() => languages.map(l => ({ label: l.toUpperCase(), value: l })), [])
  const history = useHistory()

  const location = useLocation()

  const lang = useLang()

  const setSelectedLanguageWrapper = useCallback(newLang => {
    if(newLang !== lang) {
      const oldLangPrefix = `/${lang}/`
      const newLangPrefix = `/${newLang}/`
      let pathname = location.pathname
      if(pathname.startsWith(oldLangPrefix)) {
        pathname = newLangPrefix + pathname.substr(oldLangPrefix.length)
      } else {
        pathname = newLangPrefix.substr(0, newLangPrefix.length - 1) + pathname
      }
      if(pathname === '/de/') {
        pathname = '/'
      }
      const search = location.search
      const newRoute = pathname + search

      history.push(newRoute)
    }
  }, [lang, location, history])

  return (

      <Navbar variant="dark" expand="lg">
          <Container>
              {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
              <LinkLangQueryParamStyled to="/"><img src={Logo} style={{width: '14em'}} /></LinkLangQueryParamStyled>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                      <NavbarItem><LinkLangQueryParamStyled to="/"><FormattedMessage id="navbar.home" /></LinkLangQueryParamStyled></NavbarItem>
                      <NavbarItem><LinkLangQueryParamStyled to="/immoscout"><FormattedMessage id="navbar.immoscout" /></LinkLangQueryParamStyled></NavbarItem>
                      <NavbarItem><LinkLangQueryParamStyled to="/news"><FormattedMessage id="navbar.news" /></LinkLangQueryParamStyled></NavbarItem>
                      <NavbarItem><LinkLangQueryParamStyled to="/faq"><FormattedMessage id="navbar.faqs" /></LinkLangQueryParamStyled></NavbarItem>
                      <NavbarItem><LinkLangQueryParamStyled to="/contact"><FormattedMessage id="navbar.contact" /></LinkLangQueryParamStyled></NavbarItem>
                      
                  </Nav>
                  <div style={{marginBottom: '-0.25em', marginRight: '2em'}}>    
                  <Dropdown theme="dark" fontStyle={{ fontWeight: 700, fontSize: '15px'}} items={translatedLanguages} placeholder="" value={lang} onChange={setSelectedLanguageWrapper} />
                  </div>
                  <div><LinkLangQueryParamStyled to="/shopping-cart"><MySelection><FormattedMessage id="navbar.mySelection" /> ({ get(shoppingCart, 'products.length') || 0 })</MySelection></LinkLangQueryParamStyled></div>
              </Navbar.Collapse>
          </Container>
      </Navbar>

  );
}