import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { Provider } from 'react-redux'
import store from './redux/store'
import { IntlProviderQueryParam, LangRouter } from './components/langutils'
import React,{ useEffect } from 'react';

import { 
  StartPage, 
  SearchResults, 
  Payment, 
  ShoppingCart, 
  Summary, 
  ThankYou, 
  Download, 
  News, 
  Faq, 
  Contact, 
  Imprint,
  Immoscout,
  Privacy,
  BusinessTerms,
} from './anima';

import Sitemap from './components/sitemap';
import NavbarReact from './components/Navbar/navbar-react';
import NotificationWrapper from './components/notification';

import ScrollToTop from './components/scrollToTop';
import CookieConsent from './components/cookie-consent';

import { useMatomo} from '@datapunt/matomo-tracker-react';
import { initCookieUsage } from './service/cookie-service';

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  const { trackPageView, trackEvent } = useMatomo();

  // Track page view
  React.useEffect(() => {
    
    /*if(window.location.host == "www.noisemap.eu")
    { 
      console.log("Matomo tracking starting");
      trackPageView()
    }*/

    initCookieUsage();
  }, [])

  return (
    <Provider store={store}>
      <Router>
        <LangRouter>
          <IntlProviderQueryParam>
            <CookieConsent />
            <NotificationWrapper>
              <div style={{ /*display: "flex", flexDirection: "row" */}}>
                <div style={{ flex: 1 }}></div>
                <div style={{ flexDirection: 'column '}}>
                  
                  
                  <NavbarReact />

                  


                  <ScrollToTop />
                  <Switch>
                  <Route path="/sitemap">
                      <Sitemap />
                    </Route>
                    <Route path="/:lang/search">
                      <SearchResults />
                    </Route>
                    <Route path="/:lang/shopping-cart">
                      <ShoppingCart />
                    </Route>
                    <Route path="/:lang/payment">
                      <Payment />
                    </Route>
                    <Route path="/:lang/summary">
                      <Summary />
                    </Route>
                    <Route path="/:lang/thank-you">
                      <ThankYou />
                    </Route>
                    <Route path="/:lang/download/:key">
                      <Download />
                    </Route>
                    <Route path="/:lang/news/:path">
                      <News />
                    </Route>
                    <Route path="/:lang/news">
                      <News />
                    </Route>
                    <Route path="/:lang/faq">
                      <Faq />
                    </Route>
                    <Route path="/:lang/contact">
                      <Contact />
                    </Route>
                    <Route path="/:lang/imprint">
                      <Imprint />
                    </Route>
                    <Route path="/:lang/immoscout">
                      <Immoscout />
                    </Route>
                    <Route path="/:lang/privacy">
                      <Privacy />
                    </Route>
                    <Route path="/:lang/business-terms">
                      <BusinessTerms />
                    </Route>
                    <Route path="/:lang/">
                      <StartPage />
                    </Route>
                    <Route path="/">
                      <StartPage />
                    </Route>
                    
                  </Switch>
                </div>
                <div style={{ flex: 1 }}></div>
              </div>
            </NotificationWrapper>
          </IntlProviderQueryParam>
        </LangRouter>
      </Router>
    </Provider>
  );
}

export default App;
