import React from 'react'
import styled from 'styled-components'

import BackgroundImg from '../assets/inlinesearch.jpg'
import FormattedHTMLMessage from './formattedhtmlmessage'
import SeachBar from './searchbar'
import './inlinesearchbar.scss'

const Wrapper = styled.div`
  height: fit-content;
  position: relative;
  padding-top: 50px;
  background-image: url(${BackgroundImg});
  background-repeat: no-repeat;
  background-position: center;
`

const Text = styled.div`
  background-color: transparent;
  color: rgba(255,255,255,1.0);
  font-weight: 400;
  height: auto;
  left: 419px;
  line-height: 56px;
  text-align: center;
  margin-top: 100px;

  font-family: 'Montserrat', Helvetica, Arial, serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.00px;
`

export default function InlineSearchBar() {
  return (
    <div className="inlinesearchbar-container" style={{backgroundImage: `url(${BackgroundImg})`}}>
      <div className="inlinesearchbar-title"><FormattedHTMLMessage id="inlinesearchbar.howNoisy" /></div>
      <SeachBar theme="dark" />
    </div>
  )
}
