import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { Column, Row, Filler } from '../../components/base';
import { FormRow, FormLabel, FormInputField, FormInputArea } from '../../components/form';
import Footer from '../../components/footer';
import FormattedHTMLMessage from '../../components/formattedhtmlmessage';

import "./style.scss";
import "./style2.scss";

import BackgroundImage from '../../assets/news.jpg';
import ThankYouImage from '../../assets/thank-you-text-mail.png';
import Jumbotron from '../../components/jumbotron';
import SectionHead from '../../components/sectionhead';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../../components/button';
import { get } from 'lodash';
import Loading from '../../components/loading';

const Text = styled.div`
  margin-top: 0.5em;
  background-color: transparent;
  color: var(--midnight-blue);
  font-weight: 400;
  height: auto;
  left: 0px;
  line-height: 30px;
  text-align: left;
  font-family: 'PT Sans', Helvetica, Arial, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.00px;

  p {
    margin-top: 1em;
  }
`

const Label = styled.div`
  color: rgba(0, 53, 95, 1);
  font-family: "PT Sans", Helvetica;
  font-size: 18px;
  font-style: normal;
`

function App() {  
  const intl = useIntl()
  const [contactData, setContactData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  })
  const [errors, setErrors] = useState([])
  const [messageSent, setMessageSent] = useState(false)
  const [sending, setSending] = useState(false)

  const resetError = useCallback(errorId => {
    const newErrors = errors.filter(e => e !== errorId)
    setErrors(newErrors)
  }, [errors, setErrors])

  const setContactDataValue = useCallback((key, value, errorKey) => {
    resetError(errorKey)
    const newContactData = {...contactData, [key]: value}
    setContactData(newContactData)
  }, [contactData, setContactData, resetError])

  const validate = useCallback(() => {
    const errors = []
    if(!get(contactData, "name.length")) {
      errors.push("name_is_null")
    }
    if(!get(contactData, "email.length")) {
      errors.push("email_is_null")
    }
    if(!get(contactData, "subject.length")) {
      errors.push("subject_is_null")
    }
    if(!get(contactData, "message.length")) {
      errors.push("message_is_null")
    }
    setErrors(errors)

    return !errors.length
  }, [contactData, setErrors])

  const send = useCallback(async () => {
    if(validate()) {
      setSending(true)
      await fetch(`/api/contactform/`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(contactData)
      })
      setSending(false)
      setMessageSent(true)
    }
  }, [validate, contactData, setMessageSent, setSending])

  const content = intl.formatMessage({id: "contact.content"})

  /*return (
    <div className="suchergebnis">
      <Jumbotron title={<FormattedHTMLMessage id="contact.title" />} img={BackgroundImage} />
      <Row style={{ width: "80%" }}>
        <Filler />
        <Column style={{width: '78%', paddingTop: '3em', paddingBottom: '3em'}}>
            <SectionHead><FormattedHTMLMessage id="contact.header" /></SectionHead>

            <Text dangerouslySetInnerHTML={{ __html: content }} />
            </Column>
        <Filler />
      </Row>
      { !messageSent && 
      <Row style={{ width: "80%" }}>
        <Filler />
        <Column style={{width: '78%', paddingTop: '3em', paddingBottom: '3em'}}>
            <SectionHead><FormattedMessage id="contact.contactForm" /></SectionHead>
            <FormRow><FormLabel><FormattedMessage id="contact.name" /> *</FormLabel><FormInputField placeholder={intl.formatMessage({id: "contact.name"})} value={contactData.name || ''} onChange={e => setContactDataValue('name', e.target.value, 'name_is_null')} errors={errors.filter(e => e === "name_is_null").map(e => <FormattedMessage id="contact.name.error" />)} /><Filler /></FormRow>
            <FormRow><FormLabel><FormattedMessage id="contact.email" /> *</FormLabel><FormInputField placeholder={intl.formatMessage({id: "contact.email"})} value={contactData.email || ''} onChange={e => setContactDataValue('email', e.target.value, 'email_is_null')} errors={errors.filter(e => e === "email_is_null").map(e => <FormattedMessage id="contact.email.error" />)} /><Filler /></FormRow>
            <FormRow><FormLabel><FormattedMessage id="contact.phone" /></FormLabel><FormInputField placeholder={intl.formatMessage({id: "contact.phone.optional"})} value={contactData.phone || ''} onChange={e => setContactDataValue('phone', e.target.value, 'phone_is_null')} errors={errors.filter(e => e === "phone_is_null").map(e => <FormattedMessage id="contact.phone.error" />)} /><Filler /></FormRow>
            <FormRow><FormLabel><FormattedMessage id="contact.subject" /> *</FormLabel><FormInputField placeholder={intl.formatMessage({id: "contact.subject"})} value={contactData.subject || ''} onChange={e => setContactDataValue('subject', e.target.value, 'subject_is_null')} errors={errors.filter(e => e === "subject_is_null").map(e => <FormattedMessage id="contact.subject.error" />)} /><Filler /></FormRow>
            <FormRow><FormLabel><FormattedMessage id="contact.message" /> *</FormLabel><FormInputArea style={{ flex: 2, height: '10em' }} placeholder={intl.formatMessage({id: "contact.message"})} value={contactData.message || ''} onChange={e => setContactDataValue('message', e.target.value, 'message_is_null')} errors={errors.filter(e => e === "message_is_null").map(e => <FormattedMessage id="contact.message.error" />)} /></FormRow>
            
            {
              sending && 
              <FormRow><Filler /><Loading /> <span style={{ color: "var(--midnight-blue)", fontFamily: "PT Sans, Helvetica" }}>&nbsp;<FormattedMessage id="contact.sendingMessage" /></span></FormRow>
                
            }
            { !sending &&
              <FormRow><Filler /><Button label={<FormattedMessage id="contact.send" />} onClick={send} /></FormRow>
            }
        </Column>
        <Filler />
      </Row>
      }
      { messageSent &&
      <Row style={{ width: "80%" }}>
        <Filler />
        <Column  style={{width: '78%', paddingTop: '3em', paddingBottom: '3em'}}>
          <SectionHead><FormattedMessage id="contact.contactForm" /></SectionHead>
          <div style={{ position: "relative" }}>
            <img style={{ width: '100%', marginLeft: "auto", marginRight: "auto" }} src={ThankYouImage} />
            <div style={{ position: "absolute", top: "180px", left: "320px" }}>
              <SectionHead><FormattedHTMLMessage id="contact.thankYou.head" /></SectionHead>
              <Label><FormattedHTMLMessage id="contact.thankYou.content" /></Label>
            </div>
          </div>
          
        </Column>
        <Filler />
      </Row>
      }
      
      <Footer />
    </div>
  );*/

  return (

    <div className="contact-container ">
      <Jumbotron title={<FormattedHTMLMessage id="contact.title" />} img={BackgroundImage} />

      <div className="content-container mobile-text-container">
        <SectionHead><FormattedHTMLMessage id="contact.header" /></SectionHead>
        <Text dangerouslySetInnerHTML={{ __html: content }} />

        
        {/* <FormRow><FormLabel><FormattedMessage id="contact.name" /> *</FormLabel><FormInputField placeholder={intl.formatMessage({ id: "contact.name" })} value={contactData.name || ''} onChange={e => setContactDataValue('name', e.target.value, 'name_is_null')} errors={errors.filter(e => e === "name_is_null").map(e => <FormattedMessage id="contact.name.error" />)} /><Filler /></FormRow>
        <FormRow><FormLabel><FormattedMessage id="contact.email" /> *</FormLabel><FormInputField placeholder={intl.formatMessage({ id: "contact.email" })} value={contactData.email || ''} onChange={e => setContactDataValue('email', e.target.value, 'email_is_null')} errors={errors.filter(e => e === "email_is_null").map(e => <FormattedMessage id="contact.email.error" />)} /><Filler /></FormRow>
        <FormRow><FormLabel><FormattedMessage id="contact.phone" /></FormLabel><FormInputField placeholder={intl.formatMessage({ id: "contact.phone.optional" })} value={contactData.phone || ''} onChange={e => setContactDataValue('phone', e.target.value, 'phone_is_null')} errors={errors.filter(e => e === "phone_is_null").map(e => <FormattedMessage id="contact.phone.error" />)} /><Filler /></FormRow>
        <FormRow><FormLabel><FormattedMessage id="contact.subject" /> *</FormLabel><FormInputField placeholder={intl.formatMessage({ id: "contact.subject" })} value={contactData.subject || ''} onChange={e => setContactDataValue('subject', e.target.value, 'subject_is_null')} errors={errors.filter(e => e === "subject_is_null").map(e => <FormattedMessage id="contact.subject.error" />)} /><Filler /></FormRow>
        <FormRow><FormLabel><FormattedMessage id="contact.message" /> *</FormLabel><FormInputArea style={{ flex: 2, height: '10em' }} placeholder={intl.formatMessage({ id: "contact.message" })} value={contactData.message || ''} onChange={e => setContactDataValue('message', e.target.value, 'message_is_null')} errors={errors.filter(e => e === "message_is_null").map(e => <FormattedMessage id="contact.message.error" />)} /></FormRow> */}
        {!messageSent &&
          <React.Fragment>
            <SectionHead><FormattedMessage id="contact.contactForm" /></SectionHead>
            <div className="input-container">
              <div className="contact-label"><FormattedMessage id="contact.name" /> *</div>
              <FormInputField placeholder={intl.formatMessage({ id: "contact.name" })} value={contactData.name || ''} onChange={e => setContactDataValue('name', e.target.value, 'name_is_null')} errors={errors.filter(e => e === "name_is_null").map(e => <FormattedMessage id="contact.name.error" />)} />
            </div>
            <div className="input-container">
              <div className="contact-label"><FormattedMessage id="contact.email" /> *</div>
              <FormInputField placeholder={intl.formatMessage({ id: "contact.email" })} value={contactData.email || ''} onChange={e => setContactDataValue('email', e.target.value, 'email_is_null')} errors={errors.filter(e => e === "email_is_null").map(e => <FormattedMessage id="contact.email.error" />)} />
            </div>
            <div className="input-container">
              <div className="contact-label"><FormattedMessage id="contact.phone" /> *</div>
              <FormInputField placeholder={intl.formatMessage({ id: "contact.phone.optional" })} value={contactData.phone || ''} onChange={e => setContactDataValue('phone', e.target.value, 'phone_is_null')} errors={errors.filter(e => e === "phone_is_null").map(e => <FormattedMessage id="contact.phone.error" />)} />
            </div>
            <div className="input-container">
              <div className="contact-label"><FormattedMessage id="contact.subject" /> *</div>
              <FormInputField placeholder={intl.formatMessage({ id: "contact.subject" })} value={contactData.subject || ''} onChange={e => setContactDataValue('subject', e.target.value, 'subject_is_null')} errors={errors.filter(e => e === "subject_is_null").map(e => <FormattedMessage id="contact.subject.error" />)} />
            </div>
            <div className="input-container message-container">
              <div className="contact-label"><FormattedMessage id="contact.message" /> *</div>
              <FormInputArea style={{ flex: 2, height: '10em' }} placeholder={intl.formatMessage({ id: "contact.message" })} value={contactData.message || ''} onChange={e => setContactDataValue('message', e.target.value, 'message_is_null')} errors={errors.filter(e => e === "message_is_null").map(e => <FormattedMessage id="contact.message.error" />)} />
            </div>

            {
              sending &&
              <FormRow><Filler /><Loading /> <span style={{ color: "var(--midnight-blue)", fontFamily: "PT Sans, Helvetica" }}>&nbsp;<FormattedMessage id="contact.sendingMessage" /></span></FormRow>

            }
            {!sending &&
              <FormRow><Filler /><Button label={<FormattedMessage id="contact.send" />} onClick={send} /></FormRow>
            }
          </React.Fragment>
        }

        

        {messageSent &&
          
          <div className="img-container">
            <img  src={ThankYouImage} />
          </div>
        }
      </div>
      <Footer />
    </div>
  );
}

export default App;