import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Column, Filler, Row } from '../../../components/base';
import Footer from '../../../components/footer';
import InputField from '../../../components/inputfield';
import InputFieldIban from '../../../components/inputfield-iban';
import InputFieldCreditCardNumber from '../../../components/inputfield-creditcard-number';
import InputFieldCreditCardValidUntil from '../../../components/inputfield-creditcard-validuntil';
import InputFieldCreditCardCVV from '../../../components/inputfield-creditcard-cvv';
import Dropdown from '../../../components/dropdown';
import styled from 'styled-components';
import Button from '../../../components/button';
import Jumbotron from '../../../components/jumbotron';
import FormattedHTMLMessage from '../../../components/formattedhtmlmessage';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SET_PAYMENT_DATA } from '../../../redux/payment-data-reducer'

import BackgroundImg from '../../../assets/checkout.png'

import "./style.scss";
import "./style2.scss";
import { get } from 'lodash';
import { useHistoryLangQueryParam } from '../../../components/langutils';
import InputErrMsg from '../../../components/inputerrmsg';


const SectionHead = styled.div`
  color: rgba(0, 53, 95, 1);
  font-family: "PT Sans", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 2em;
  margin-top: 4em;
  border-bottom: 1px solid rgba(0, 53, 95, 1);
`

const Label = styled.div`
  color: rgba(0, 53, 95, 1);
  font-family: "PT Sans", Helvetica;
  font-size: 18px;
  font-style: normal;
  padding: 0.7em;
`
const FormInputField = styled(InputField)`
  flex: 1;
`

const FormInputFieldIban = styled(InputFieldIban)`
  flex: 1;
`

const FormInputFieldCreditCardNumber = styled(InputFieldCreditCardNumber)`
  flex: 1;
`

const FormInputFieldCreditCardValidUntil = styled(InputFieldCreditCardValidUntil)`
  flex: 1;
`

const FromInputFieldCreditCardCVV = styled(InputFieldCreditCardCVV)`
  flex: 1;
`

const FormDropdown = styled(Dropdown)`
  flex: 1;
`

const paymentMethods = ["PAYPAL","DEBIT", "CREDIT_CARD"]
  
function App() {
  const [errors, setErrors] = useState([])
  const paymentData = useSelector(({ paymentData }) => paymentData)
  const intl = useIntl()
  const [sepaMandateAccepted, setSepaMandateAccepted] = useState(false)

  const dispatch = useDispatch()

  const history = useHistoryLangQueryParam();

  const paymentMethodsTranslated = useMemo(() => paymentMethods.map(m => ({ label: intl.formatMessage({id: `checkout.paymentMethod.${m}`}), value: m})), [intl])

  useEffect(() => {
    setPaymentDataValue('paymentMethod', 'PAYPAL', 'payment_method_is_null')
  }, [])

  const validate = useCallback(() => {
    const errors = []
    if(!get(paymentData, "email.length")) {
      errors.push("email_name_is_null")
    }
    if(!get(paymentData, "firstName.length")) {
      errors.push("first_name_is_null")
    }
    if(!get(paymentData, "lastName.length")) {
      errors.push("last_name_is_null")
    }
    if(!get(paymentData, "street.length")) {
      errors.push("street_is_null")
    }
    if(!get(paymentData, "zipCode.length")) {
      errors.push("zip_code_is_null")
    }
    if(!get(paymentData, "city.length")) {
      errors.push("city_is_null")
    }
    if(!get(paymentData, "paymentMethod.length")) {
      errors.push("payment_method_is_null")
    }
    if(paymentData.paymentMethod === 'DEBIT') {
      if(!get(paymentData, 'iban.length')) {
        errors.push("iban_is_null")
      }
      if(!get(paymentData, 'accountOwner.length')) {
        errors.push("account_owner_is_null")
      }
      if(!sepaMandateAccepted) {
        errors.push("sepa_mandate_not_accepted")
      }
    } else if(paymentData.paymentMethod === 'CREDIT_CARD') {
      if(!get(paymentData, "cardNumber.length")) {
        errors.push("card_number_is_null")
      }
      if(!get(paymentData, "cardHolder.length")) {
        errors.push("card_holder_is_null")
      }
      if(!(paymentData.validUntil || "").replace("/", "").length) {
        errors.push("valid_until_is_null")
      }
      if(!get(paymentData, "cvv.length")) {
        errors.push("cvv_is_null")
      }
    }
    setErrors(errors)

    return !errors.length
  }, [paymentData, sepaMandateAccepted, setErrors])

  const resetError = useCallback(errorId => {
    const newErrors = errors.filter(e => e !== errorId)
    setErrors(newErrors)
  }, [errors, setErrors])

  const setPaymentDataValue = useCallback((key, value, errorKey) => {
    resetError(errorKey)
    const newPaymentData = {...paymentData, [key]: value}
    delete newPaymentData.paymentId
    dispatch({type: SET_PAYMENT_DATA, paymentData: newPaymentData})
  }, [paymentData, dispatch, resetError])

  return (
    <div className="payment-container">
      <Jumbotron title={<FormattedHTMLMessage id="checkout.payment.title" />} img={BackgroundImg} />
      <div className="content-container">
        <SectionHead><FormattedMessage id="checkout.section.invoiceAddress" /></SectionHead>

        <div className="input-container row">
          <div className="contact-label col-md-3">
            <FormattedMessage id="checkout.email" /> *
          </div>
          <div className="col-md-9">
            <FormInputField placeholder={intl.formatMessage({id: "checkout.email"})} value={paymentData.email || ''} onChange={e => setPaymentDataValue('email', e.target.value, 'email_name_is_null')} errors={errors.filter(e => e === "email_name_is_null").map(e => <FormattedMessage id="checkout.email.error" />)} />
          </div>
        </div>
        <div className="input-container row">
          <div className="contact-label col-md-3">
            <FormattedMessage id="checkout.firstName" /> *
          </div>
          <div className="col-md-9">
            <FormInputField placeholder={intl.formatMessage({id: "checkout.firstName"})} value={paymentData.firstName || ''} onChange={e => setPaymentDataValue('firstName', e.target.value, 'first_name_is_null')} errors={errors.filter(e => e === "first_name_is_null").map(e =>  <FormattedMessage id="checkout.firstName.error" />)} />
          </div>
        </div>
        <div className="input-container row">
          <div className="contact-label col-md-3">
            <FormattedMessage id="checkout.lastName" /> *
          </div>
          <div className="col-md-9">
            <FormInputField placeholder={intl.formatMessage({id: "checkout.lastName"})} value={paymentData.lastName || ''} onChange={e => setPaymentDataValue('lastName', e.target.value, 'last_name_is_null')} errors={errors.filter(e => e === "last_name_is_null").map(e =>  <FormattedMessage id="checkout.lastName.error" />)} />
          </div>
        </div>
        <div className="input-container row">

          <div className="contact-label col-md-3">
            <FormattedMessage id="checkout.streetHouseNumber" /> *
          </div>
          <div className="col-md-8">
            <FormInputField
              placeholder={intl.formatMessage({ id: "checkout.street" })}
              value={paymentData.street || ''}
              onChange={e => setPaymentDataValue('street', e.target.value, 'street_is_null')}
              errors={errors.filter(e => e === "street_is_null").map(e => <FormattedMessage
                id="checkout.street.error" />)} />
          </div>
          <div className="col-md-1">
            <FormInputField
              placeholder={intl.formatMessage({ id: "checkout.houseNumber" })}
              value={paymentData.houseNumber || ''}
              onChange={e => setPaymentDataValue('houseNumber', e.target.value, 'house_number_is_null')} />
          </div>
        </div>
        <div className="input-container row">
          <div className="contact-label col-md-3">
            <FormattedMessage id="checkout.zipCodeCity" /> *
          </div>
          <div className="col-md-3">
            <FormInputField placeholder={intl.formatMessage({id: "checkout.zipCode"})} value={paymentData.zipCode || ''} onChange={e => setPaymentDataValue('zipCode', e.target.value, 'zip_code_is_null')} errors={errors.filter(e => e === "zip_code_is_null").map(e => <FormattedMessage id="checkout.zipCode.error" />)} />
          </div>
          <div className="col-md-6">
            <FormInputField placeholder={intl.formatMessage({id: "checkout.city"})} value={paymentData.city || ''} onChange={e => setPaymentDataValue('city', e.target.value, 'city_is_null')} errors={errors.filter(e => e === "city_is_null").map(e =>  <FormattedMessage id="checkout.city.error" />)} />
          </div>
        </div>
        <div className="input-container row">
          <div className="contact-label col-md-3">
            <FormattedMessage id="checkout.companyName" /> 
          </div>
          <div className="col-md-9">
            <FormInputField placeholder={intl.formatMessage({id: "checkout.companyName"})} value={paymentData.companyName || ''} onChange={e => setPaymentDataValue('companyName', e.target.value, 'company_name_is_null')} errors={errors.filter(e => e === "company_name_is_null")} />
          </div>
        </div>
        <div className="input-container row">
          <div className="contact-label col-md-3">
            <FormattedMessage id="checkout.taxId" /> 
          </div>
          <div className="col-md-9">
            <FormInputField placeholder={intl.formatMessage({id: "checkout.taxId"})} value={paymentData.taxId || ''} onChange={e => setPaymentDataValue('taxId', e.target.value, 'tax_id_is_null')} errors={errors.filter(e => e === "tax_id_is_null")} />
          </div>
        </div>
      </div>

      <div className="content-container">
        <SectionHead><FormattedMessage id="checkout.section.paymentMethod" /></SectionHead>
        <FormattedMessage id="checkout.paymentMethod" /> *<FormDropdown items={paymentMethodsTranslated} placeholder="Zahlungsweise" value={paymentData.paymentMethod} onChange={v => setPaymentDataValue('paymentMethod', v, 'payment_method_is_null')} errors={errors.filter(e => e === "payment_method_is_null").map(e => <FormattedMessage id="checkout.paymentMethod.error" />)} />
        {
          paymentData.paymentMethod === 'PAYPAL' &&
          <>
            <div className="input-container row">
              <p>Auf der nächsten Seite können Sie die Zahlung über Paypal abschließen</p>
            </div>
            
          </>
        }
        
        {
          paymentData.paymentMethod === 'DEBIT' &&
          <>
            <div className="input-container row">
              <div className="contact-label col-md-3">
                <FormattedMessage id="checkout.iban" /> *
              </div>
              <div className="col-md-9">
                <FormInputFieldIban placeholder={intl.formatMessage({ id: "checkout.iban" })} value={paymentData.iban} onChange={e => setPaymentDataValue('iban', e.target.value, 'iban_is_null')} errors={errors.filter(e => e === "iban_is_null").map(e => <FormattedMessage id="checkout.iban.error" />)} />
              </div>
            </div>
            <div className="input-container row">
              <div className="contact-label col-md-3">
                <FormattedMessage id="checkout.accountOwner" /> *
              </div>
              <div className="col-md-9">
                <FormInputField placeholder={intl.formatMessage({ id: "checkout.accountOwner" })} value={paymentData.accountOwner} onChange={e => setPaymentDataValue('accountOwner', e.target.value, 'account_owner_is_null')} errors={errors.filter(e => e === "account_owner_is_null").map(e => <FormattedMessage id="checkout.accountOwner.error" />)} />
              </div>
            </div>
            <div className="input-container row sepa-container">

              <div className="col-md-3 sepa-check">
                <input type="checkbox" value={sepaMandateAccepted} onChange={e => resetError("sepa_mandate_not_accepted") || setSepaMandateAccepted(e.target.checked)} />
              </div>
              <div className="contact-label sepa-text col-md-9">
                <FormattedMessage id="checkout.sepaMandate" /> *<br/><InputErrMsg errors={errors.filter(e => e === "sepa_mandate_not_accepted").map(e => <FormattedMessage id="checkout.sepaMandate.error" />)} />
              </div>
            </div>
            
          </>
        }
         {
            paymentData.paymentMethod === 'CREDIT_CARD' &&
            <>
            <div className="input-container row">
              <div className="contact-label col-md-3">
                <FormattedMessage id="checkout.cardNumber" /> *
              </div>
              <div className="col-md-9">
                <FormInputFieldCreditCardNumber placeholder={intl.formatMessage({id: "checkout.cardNumber"})} value={paymentData.cardNumber} onChange={e => setPaymentDataValue('cardNumber', e.target.value, 'card_number_is_null')} errors={errors.filter(e => e === "card_number_is_null").map(e => <FormattedMessage id="checkout.cardNumber.error" />)} />
              </div>
            </div>
            <div className="input-container row">
              <div className="contact-label col-md-3">
                <FormattedMessage id="checkout.cardHolder" /> *
              </div>
              <div className="col-md-9">
                <FormInputField placeholder={intl.formatMessage({id: "checkout.cardHolder"})} value={paymentData.cardHolder} onChange={e => setPaymentDataValue('cardHolder', e.target.value, 'card_holder_is_null')} errors={errors.filter(e => e === "card_holder_is_null").map(e => <FormattedMessage id="checkout.cardHolder.error" />)} />
              </div>
            </div>
            <div className="input-container row ">
              <div className="contact-label col-md-3">
                <FormattedMessage id="checkout.validUntil" /> *
              </div>
              <div className="col-md-9">
                <FormInputFieldCreditCardValidUntil placeholder={intl.formatMessage({id: "checkout.validUntil"})} value={paymentData.validUntil} onChange={e => setPaymentDataValue('validUntil', e.target.value, 'valid_until_is_null')} errors={errors.filter(e => e === "valid_until_is_null").map(e => <FormattedMessage id="checkout.validUntil.error" />)} />
              </div>
            </div>
            <div className="input-container row">
              <div className="contact-label col-md-3">
                <FormattedMessage id="checkout.cvv" /> *
              </div>
              <div className="col-md-9">
                <FromInputFieldCreditCardCVV placeholder={intl.formatMessage({id: "checkout.cvv"})} value={paymentData.cvv} onChange={e => setPaymentDataValue('cvv', e.target.value, 'cvv_is_null')} errors={errors.filter(e => e === "cvv_is_null").map(e => <FormattedMessage id="checkout.cvv.error" />)} />
              </div>
            </div>
            </>
          }
      </div>

      <div className="content-container payment-button">
        <SectionHead></SectionHead>
        <Button label={<FormattedMessage id="checkout.continue" />} onClick={() => validate() && history.push('/summary')} />
      </div>

      <Footer />
    </div>
  );
}

export default App;

function Topnav(props) {
  const { logo, place, text1, neues, faqs, kontaktCopy, meineAuswahl1 } = props;

  return (
    <div className="topnav">
      <img className="logo" src={logo} />
      <div className="menu">
        <div className="place ptsans-bold-white-15px">{place}</div>
        <div className="text-1 ptsans-bold-white-15px">{text1}</div>
        <div className="neues ptsans-bold-white-15px">{neues}</div>
        <div className="fa-qs ptsans-bold-white-15px">{faqs}</div>
        <div className="kontakt-copy ptsans-bold-white-15px">{kontaktCopy}</div>
      </div>
      <div className="meine-auswahl-1 ptsans-bold-bondi-blue-15px">{meineAuswahl1}</div>
    </div>
  );
}

function ButtonPrimaryFilledActive(props) {
  const { children, className } = props;

  return (
    <div className={`button-fill-1 ${className || ""}`}>
      <div className="overlap-group2-1">
        <div className="label bodyparagraph">{children}</div>
      </div>
    </div>
  );
}


function ButtonPrimaryFilledActive2(props) {
  const { children, className } = props;

  return (
    <div className={`button-fill-copy ${className || ""}`}>
      <div className="overlap-group4">
        <div className="label-1 bodyparagraph">{children}</div>
      </div>
    </div>
  );
}


function CardPlainDetailExpose(props) {
  const {
    bitmap,
    jobdesk,
    name,
    text,
    detail,
    buttonPrimaryFilledActiveProps,
    buttonPrimaryFilledActive2Props,
  } = props;

  return (
    <div className="card-plain-detail-expose">
      <div className="overlap-group5">
        <div className="x1-1">
          <img className="bitmap-1" src={bitmap} />
        </div>
        <div className="jobdesk-1 ptsans-normal-midnight-blue-13px">{jobdesk}</div>
        <div className="name-1 bodytitle">{name}</div>
        <div className="text-2 heroparagraph">{text}</div>
        <ButtonPrimaryFilledActive className="button-fill">
          {buttonPrimaryFilledActiveProps.children}
        </ButtonPrimaryFilledActive>
        <ButtonPrimaryFilledActive2 className="button-fill-copy-1">
          {buttonPrimaryFilledActive2Props.children}
        </ButtonPrimaryFilledActive2>
        <div className="detail ptsans-bold-white-50px">{detail}</div>
      </div>
    </div>
  );
}

const buttonPrimaryFilledActiveData = {
    children: "Direktkauf",
};

const buttonPrimaryFilledActive2Data = {
    children: "In den Warenkorb",
};

