const PaymentService = {
    test: () => {
        alert("mops");
    },
    getPaymentMethodAttrDebit: (paymentData) => {
        return {
            iban: paymentData.iban,
            accountOwner: paymentData.accountOwner,
            name: `${paymentData.firstName} ${paymentData.lastName}`,
            email: paymentData.email,
        }
    },
    getPaymentMethodAttrCreditCard: (paymentData) => {
        return {
            cardNumber: paymentData.cardNumber,
            cardHolder: paymentData.cardHolder,
            expMonth: paymentData.validUntil.split("/")[0],
            expYear: paymentData.validUntil.split("/")[1],
            cvc: paymentData.cvv
        }
    },
    getPaymentRequest: (paymentData, paymentMethodAttrs, shoppingCart) => {
        return {
            paymentMethod: paymentData.paymentMethod,
            paymentMethodAttrs,
            amount: shoppingCart.sum,
            description: "Einkauf mena-online.de"
        }
    },
    fetchInitialPaymentStripe: async (paymentRequest) =>  {
        return await fetch('/api/payment/payment', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(paymentRequest)
        })
    },
    fetchInitialPaymentPaypal: async (paymentRequest,lang) =>  {
        //return await await fetch(`http://localhost:8080/payment/${lang}`, {
        return await fetch(`/api/paypal/payment/${lang}`, {    
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(paymentRequest)
        })
    },
    fetchPaymentConfirmationPaypal: async (paymentData, purchaseSig) => {
        //return await fetch(`http://localhost:8080/payment/${paymentData.paymentId}/confirm`, {
        return await fetch(`/api/paypal/payment/${paymentData.paymentId}/confirm`, {    
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ hash: purchaseSig.sig })
        })
    },
    fetchPaymentSignedPaypal: async (paymentData) => {
        //return await fetch(`http://localhost:8080/payment/${paymentData.paymentId}/signed`, {
        return await fetch(`/api/paypal/payment/${paymentData.paymentId}/signed`, {    
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
    },
    fetchPaymentSignedStripe: async (paymentData) => {
        return await fetch(`/api/payment/payment/${paymentData.paymentId}/signed`, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          })
    },
    
}

export default PaymentService