import { useMemo } from 'react'
import { IntlProvider as IntlProviderReact } from 'react-intl'
import { useHistory, useLocation, Link, useParams, Router, Switch, Route } from 'react-router-dom'

import MessagesDe from '../messages-de.json'
import MessagesEn from '../messages-en.json'

export function IntlProviderQueryParam({...rest}) {
  const lang = useLang()

  const messages = lang === 'en' ? MessagesEn : MessagesDe

  return <IntlProviderReact messages={messages} locale="de" {...rest} />
}

export function useHistoryLangQueryParam() {
  const lang = useLang()

  const history = useHistory()
  //const lang = useQuery().get('lang') || 'de'

  const result = useMemo(() => ({
    ...history,
    push: path => {
      console.log("Pushing history", lang, path)
      const newPath = lang !== 'de' || path !== '/' ? `/${lang}${path}` : '/'
      console.log('David ' + newPath);
      history.push(newPath)
    },
    replace: path => {
      const newPath = lang !== 'de' || path !== '/' ? `/${lang}${path}` : '/'
      history.replace(newPath)
    },
  }), [history, lang])

  //console.log('David ' + result);
  return result
}

export function LinkLangQueryParam({to, ...rest}) {
  const lang = useLang()
  if(to !== '/' || lang !== 'de') {
    to = `/${lang}${to}`
  }

  return <Link to={to} {...rest} />
}

export function useLang() {
  let { lang } = useParams()
  if(!lang) {
    lang = 'de'
  }
  //console.log("Selected lang: ", lang)
  return lang
}

export function LangRouter({ children }) {
  return (
    <Switch>
      <Route path="/:lang">
        { children }
      </Route>
      <Route path="/">
        { children }
      </Route>
    </Switch>
  )
}