import React, { useEffect, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl'
import { Column, Filler, Row } from '../../../components/base';
import FormattedHTMLMessage from '../../../components/formattedhtmlmessage';
import Footer from '../../../components/footer';
import Jumbotron from '../../../components/jumbotron';
import styled from 'styled-components';
import PaymentService from '../../../service/payment-service';
import PurchaseService from '../../../service/purchase-service';

import "./style.scss";
import "./style2.scss";
import Button from '../../../components/button';
import { useDispatch, useSelector } from 'react-redux';
import ProductRow from '../../../components/productrow';
import SectionHead from '../../../components/sectionhead';
import FormatCurrency from '../../../util/format-currency';
import { SET_PAYMENT_DATA } from '../../../redux/payment-data-reducer';
import { useHistory } from 'react-router-dom';

import BackgroundImg from '../../../assets/checkout.png'
import PaypalButton from '../../../assets/checkout-logo-large-de-2x.png'
import { get } from 'lodash';
import Loading from '../../../components/loading';
import { useHistoryLangQueryParam } from '../../../components/langutils';
import { CLEAR_SHOPPING_CART } from '../../../redux/shopping-cart-reducer';

import queryString from 'query-string';

const Label = styled.div`
  color: rgba(0, 53, 95, 1);
  font-family: "PT Sans", Helvetica;
  font-size: 18px;
  font-style: normal;
  padding: 0.7em;
`

const FormRow = styled(Row)`
  margin-bottom: 0.5em;
`

const FormLabel = styled(Label)`
  flex: 1;
  text-align: right;
`

const FormValue = styled(Label)`
  flex: 1;
  font-weight: bold;
`

const NoProducts = styled.div`
  text-align: center;
  font-family: "PT Sans", Helvetica;
  color: rgba(0, 53, 95, 1);
`

const DisplaySum = styled.div`
  color: rgba(0, 53, 95, 1);
  font-family: "PT Sans", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-top: 0.5em;
`

const FrameWindowBackground = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 1;
`

const FrameWindow = styled.div`
  width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vh;
  padding: 2em;
  background-color: white;
  border: 2px solid rgba(0, 53, 95, 1);

  iframe {
    width: 100%;
    height: 80vh;
  }
`

function App() {
  const shoppingCart = useSelector(({ shoppingCart }) => shoppingCart)
  const paymentData = useSelector(({ paymentData }) => paymentData)
  const [performingPaypalCheckout, setperformingPaypalCheckout] = useState(false)
  const [performingPurchase, setPerformingPurchase] = useState(false)
  const [paymentError, setPaymentError] = useState(null)
  const [redirectUrl, setRedirectUrl] = useState(null)
  const [purchaseSig, setPurchaseSig] = useState(null)
  const [businessTermsAccepted, setBusinessTermsAccepted] = useState(false)
  const [withdrawalWaiverAccepted, setWithdrawalWaiverAccepted] = useState(false)
  const dispatch = useDispatch()
  const history = useHistoryLangQueryParam()

  const intl = useIntl()
  const lang = intl.formatMessage({id: "lang" })

  const completePurchase = useCallback(async purchaseSig => {

    try {
      let resp = await PaymentService.fetchPaymentSignedStripe(paymentData)

      const paymentConfirmation = await resp.json()

      console.log("Payment confirmation result", paymentConfirmation)

      if ((await paymentConfirmation).result) {
        resp = await PurchaseService.finilizePurchase(paymentData, purchaseSig, paymentConfirmation, lang)
        if(!((await resp).status == 200 || (await resp).status == 204))
          {
            throw "finilazing Purchase responded with error";
          }
        dispatch({ type: CLEAR_SHOPPING_CART })

        history.push('/thank-you')
      }

      const newPaymentData = { ...paymentData }
      delete newPaymentData.paymentId
      dispatch({ type: SET_PAYMENT_DATA, paymentData: newPaymentData })
    } catch (e) {
      console.error("Payment error", e)
      setPaymentError(e)
    }

    
  }, [paymentData, dispatch])

  useEffect(() => {
    //PaymentService.fetchInitialPayment();
  }, [])

  useEffect(async () => {

    //This captures the paypal payment after beeing redirected from paypal

    const urlParams = queryString.parse(window.location.search);

    if (urlParams.token) {

      setperformingPaypalCheckout(true);
      setPerformingPurchase(true)
      try {

        let purchaseSig = await createPurchaseSig();

        await PaymentService.fetchPaymentConfirmationPaypal(paymentData, purchaseSig);

        let paymentResp = await PaymentService.fetchPaymentSignedPaypal(paymentData);

        const paymentConfirmation = await paymentResp.json()

        console.log("Payment confirmation result", paymentConfirmation)

        if ((await paymentConfirmation).result) {
          const resp = PurchaseService.finilizePurchase(paymentData, purchaseSig, paymentConfirmation, lang)
          if(!((await resp).status == 200 || (await resp).status == 204))
          {
            throw "finilazing Purchase responded with error";
          }
        }

        dispatch({ type: CLEAR_SHOPPING_CART })

        history.push('/thank-you')

      } catch (e) {
        console.error("Payment error", e)
        setPaymentError(e)
      }

    }
  }, [])

  useEffect(() => {
    const eventListener = async event => {

      //event für html snippet aus service (Card Action)
      if(get(event, "data.message") === "paymentActionReturn") {
        setRedirectUrl(null);
        await completePurchase(purchaseSig);
      }
    }

    window.addEventListener('message', eventListener, false);

    return () => window.removeEventListener('message', eventListener, false);
  }, [completePurchase, purchaseSig])

  useEffect(() => {
    (async () => {
      if(!paymentData.paymentId) {
        
        try{

          const resp = await makeInitialPayment();
          if(!((await resp).status == 200 || (await resp).status == 204))
          {
            throw "Payment Initialization responded with error";
          }
          const data = await resp.json()

          const paymentId = data.id
          console.log("Creating new payment")
          
          if(paymentData.paymentMethod === 'PAYPAL')
          { 
            const redirectUrl = data.statusParam
            dispatch({ type: SET_PAYMENT_DATA, paymentData: { ...paymentData, paymentId, redirectUrl }})
          } else {
            dispatch({ type: SET_PAYMENT_DATA, paymentData: { ...paymentData, paymentId }})
          }
        } catch(e) {
          console.error("Payment Initialization error", e)
          setPaymentError(e)
        }
      }
    })()
  }, [shoppingCart, paymentData])

  const makeInitialPayment = async () => {

    let paymentMethodAttrs;
    if(paymentData.paymentMethod === 'DEBIT') {

      paymentMethodAttrs = PaymentService.getPaymentMethodAttrDebit(paymentData);
      const paymentRequest = PaymentService.getPaymentRequest(paymentData,paymentMethodAttrs, shoppingCart);
      return await PaymentService.fetchInitialPaymentStripe(paymentRequest);

    } else if(paymentData.paymentMethod === 'CREDIT_CARD') {
    
      paymentMethodAttrs = PaymentService.getPaymentMethodAttrCreditCard(paymentData);
      const paymentRequest = PaymentService.getPaymentRequest(paymentData,paymentMethodAttrs, shoppingCart);
      return await PaymentService.fetchInitialPaymentStripe(paymentRequest);

    } else if(paymentData.paymentMethod === 'PAYPAL')
    {
      paymentMethodAttrs = {}
      const paymentRequest = PaymentService.getPaymentRequest(paymentData,paymentMethodAttrs, shoppingCart);
      return await PaymentService.fetchInitialPaymentPaypal(paymentRequest, lang);
    }
  }

  const createPurchaseSig = async () => {

    let resp = await PurchaseService.createSig(shoppingCart);
    if (!((await resp).status == 200 || (await resp).status == 204)) {
      throw "Purchase Create Sig responded with error";
    }

    let purchaseSig = await resp.json()
    setPurchaseSig(purchaseSig)

    return purchaseSig;
  }

  const testPaypal = () => {
    console.log(paymentData);
    //setRedirectUrl(paymentData.redirectUrl);
    window.location.replace(paymentData.redirectUrl)
    
  }

  const confirmPayment = useCallback(async () => {
    setPerformingPurchase(true)
    try {
      const purchaseItems = shoppingCart.products.map(p => ({
        product: p,
        count: 1
      }))
      var purchaseSig = { items: purchaseItems }

      let resp = await fetch('/api/purchase/purchase/create-sig', { 
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(purchaseSig)
      })

      purchaseSig = await resp.json()
      setPurchaseSig(purchaseSig)

      await fetch(`/api/payment/payment/${paymentData.paymentId}/confirm`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({hash: purchaseSig.sig})
      })

      //https://test.noisemap.eu/api/payment/payment/6f2b6ad771334695b6ae1a3d32b8ca1e
      //resp = await fetch(`api/payment/payment/6f2b6ad771334695b6ae1a3d32b8ca1e`, {
      resp = await fetch(`/api/payment/payment/${paymentData.paymentId}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })

      const payment = await resp.json()

      console.log("Payment", payment)

      if(payment.status === 'requires_action') {
        setRedirectUrl(payment.statusParam)
      } else {
        completePurchase(purchaseSig)
      }
    } catch(e) {
      console.error("Payment error", e)
      setPaymentError(e)
    }
  }, [shoppingCart, paymentData, setRedirectUrl, setPurchaseSig, completePurchase, setPerformingPurchase])

  console.log("redirect url", redirectUrl)

  const acceptBusinessTermsText = intl.formatMessage({id: "checkout.acceptBusinessTerms" })
  const acceptWithdrawalWaiver = intl.formatMessage({id: "checkout.acceptWithdrawalWaiver" })

  return (

    <div className="summary-container">

      {
        redirectUrl &&
        <FrameWindowBackground>
          <div className="iframe-container">
            <iframe src={redirectUrl}></iframe>
          </div>
        </FrameWindowBackground>
      }

      <Jumbotron title={<FormattedHTMLMessage id="checkout.summary.title" />} img={BackgroundImg} />
      { !performingPaypalCheckout && 
      <div className="content-container">
        <SectionHead><FormattedMessage id="checkout.section.mySelection" /></SectionHead>
        {
          shoppingCart.products.length === 0 && <NoProducts><FormattedMessage id="checkout.noProducts" /></NoProducts>
        }

        {
          shoppingCart.products.map(p => <ProductRow key={JSON.stringify(p)} product={p} />)
        }

        <DisplaySum>
          <Row>
            <FormattedMessage id="checkout.totalAmount" /><Filler /><FormatCurrency value={shoppingCart.sum} /> €
          </Row>
        </DisplaySum>

        <div className="row">
          <div className="col-md-6">
            <SectionHead><FormattedMessage id="checkout.section.invoiceAddress" /></SectionHead>
            <div className="input-container">
              <div className="contact-label"><FormattedMessage id="checkout.email" /></div>
              <FormValue>{paymentData.email}</FormValue>
            </div>
            <div className="input-container">
              <div className="contact-label"><FormattedMessage id="checkout.firstName" /></div>
              <FormValue>{paymentData.firstName}</FormValue>
            </div>
            <div className="input-container">
              <div className="contact-label"><FormattedMessage id="checkout.lastName" /></div>
              <FormValue>{paymentData.lastName}</FormValue>
            </div>
            <div className="input-container">
              <div className="contact-label"><FormattedMessage id="checkout.streetHouseNumber" /></div>
              <FormValue>{paymentData.street} {paymentData.houseNumber}</FormValue>
            </div>
            <div className="input-container">
              <div className="contact-label"><FormattedMessage id="checkout.zipCodeCity" /></div>
              <FormValue>{paymentData.zipCode} {paymentData.city}</FormValue>
            </div>
            { paymentData.companyName &&
            <div className="input-container">
              <div className="contact-label"><FormattedMessage id="checkout.companyName" /></div>
              <FormValue>{paymentData.companyName}</FormValue>
            </div>
            }
            { paymentData.taxId &&
            <div className="input-container">
              <div className="contact-label"><FormattedMessage id="checkout.taxId" /></div>
                <FormValue>{paymentData.taxId}</FormValue>
            </div>
            }
            
          </div>
          <div className="col-md-6">
            <SectionHead><FormattedMessage id="checkout.section.paymentMethod" /></SectionHead>
            <div className="input-container">
              <div className="contact-label"><FormattedMessage id="checkout.paymentMethod.chosen" /></div>
              <FormValue><FormattedMessage id={"checkout.paymentMethod." + paymentData.paymentMethod}/></FormValue>
            </div>

            {
              paymentData.paymentMethod === 'DEBIT' &&
              <>
                <div className="input-container">
                  <div className="contact-label"><FormattedMessage id="checkout.iban" /></div>
                  <FormValue>{paymentData.iban}</FormValue>
                </div>
                <div className="input-container">
                  <div className="contact-label"><FormattedMessage id="checkout.accountOwner" /></div>
                  <FormValue>{paymentData.accountOwner}</FormValue>
                </div>
              </>
            }
            {
              paymentData.paymentMethod === 'CREDIT_CARD' &&
              <>

                <div className="input-container">
                  <div className="contact-label"><FormattedMessage id="checkout.cardNumber" /></div>
                  <FormValue>{"**** **** **** " + paymentData.cardNumber.substr(paymentData.cardNumber.length - 4, paymentData.cardNumber.length)}</FormValue>
                </div>
                <div className="input-container">
                  <div className="contact-label"><FormattedMessage id="checkout.cardHolder" /></div>
                  <FormValue>{paymentData.cardHolder}</FormValue>
                </div>
                <div className="input-container">
                  <div className="contact-label"><FormattedMessage id="checkout.validUntil" /></div>
                  <FormValue>{paymentData.validUntil}</FormValue>
                </div>
              </>
            }
          </div>
        </div>

        <SectionHead><FormattedMessage id="checkout.section.businessTermsPrivacy" /></SectionHead>
        
        <div className="agb-container row">
          <input className="col-md-1" id="checkboxBusinessTerms" type="checkbox" onChange={ evt => setBusinessTermsAccepted(evt.target.checked) }></input> 
          <div className="agb-label col-md-11" dangerouslySetInnerHTML={{ __html: acceptBusinessTermsText }}></div>
        </div>

        <div className="agb-container row">
          <input className="col-md-1" id="checkboxWithdrawalWaiver" type="checkbox" onChange={ evt => setWithdrawalWaiverAccepted(evt.target.checked) }></input> 
          <div className="agb-label col-md-11" dangerouslySetInnerHTML={{ __html: acceptWithdrawalWaiver }}></div>
        </div>

        <SectionHead></SectionHead>
        {
          performingPurchase && !paymentError &&
          <FormRow><Filler /><Loading /> <span style={{ color: "var(--midnight-blue)", fontFamily: "PT Sans, Helvetica" }}>&nbsp;<FormattedMessage id="checkout.performingPurchase" /></span></FormRow>

        }
        {!paymentError && !performingPurchase && paymentData.paymentMethod !== 'PAYPAL' &&
          <FormRow><Filler /><Button disabled={!businessTermsAccepted || !withdrawalWaiverAccepted} label={<FormattedMessage id="checkout.purchaseNow" />} onClick={confirmPayment} /></FormRow>
        }
        {!paymentError && !performingPurchase && paymentData.paymentMethod === 'PAYPAL' &&
          <FormRow><Filler />
            {/* <Button 
              disabled={!businessTermsAccepted || !withdrawalWaiverAccepted} 
              label={"test"} 
              onClick={confirmPayment} /> */}
            <button
              disabled={!businessTermsAccepted || !withdrawalWaiverAccepted} 
              className={'paypal-button ' + ((!businessTermsAccepted || !withdrawalWaiverAccepted) ? 'paypal-disabled' : '')}
              onClick={testPaypal}><img src={PaypalButton}/>
            
            </button>
               

          </FormRow>
        }
        {
          paymentError &&
          <FormRow><Filler /><span style={{ color: "red", fontFamily: "PT Sans, Helvetica" }}>Bei der Zahlung ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal</span></FormRow>
        }
        

      </div>
      }

      {performingPaypalCheckout && !paymentError &&

        <FormRow><Filler /><Loading /> <span style={{ color: "var(--midnight-blue)", fontFamily: "PT Sans, Helvetica" }}>&nbsp;<FormattedMessage id="checkout.performingPurchase" /></span></FormRow>

      }

      {performingPaypalCheckout && paymentError &&

        <div style={{ height: '400px' }}>
          <h2>Fehler</h2>
        </div>

      }

      <Footer />
    </div>
  );
}

export default App;

function Topnav(props) {
  const { logo, place, text1, neues, faqs, kontaktCopy, meineAuswahl1 } = props;

  return (
    <div className="topnav">
      <img className="logo" src={logo} />
      <div className="menu">
        <div className="place ptsans-bold-white-15px">{place}</div>
        <div className="text-1 ptsans-bold-white-15px">{text1}</div>
        <div className="neues ptsans-bold-white-15px">{neues}</div>
        <div className="fa-qs ptsans-bold-white-15px">{faqs}</div>
        <div className="kontakt-copy ptsans-bold-white-15px">{kontaktCopy}</div>
      </div>
      <div className="meine-auswahl-1 ptsans-bold-bondi-blue-15px">{meineAuswahl1}</div>
    </div>
  );
}


function Treffer(props) {
  const { enfernungZurGesuch, mnchenReichenh, className } = props;

  return (
    <div className={`treffer ${className || ""}`}>
      <div className="overlap-group2">
        <div className="enfernung-zur-gesuch bodytext">{enfernungZurGesuch}</div>
        <div className="mnchen-reichenh h3">{mnchenReichenh}</div>
      </div>
    </div>
  );
}


function CardPlainBasisExpose(props) {
  const { bitmap, jobdesk, name, text, basis, buttonPrimaryFilledActiveProps, buttonPrimaryFilledActive2Props } = props;

  return (
    <div className="card-plain-basis-expose">
      <div className="overlap-group3">
        <div className="x1">
          <img className="bitmap" src={bitmap} />
        </div>
        <div className="jobdesk ptsans-normal-midnight-blue-13px">{jobdesk}</div>
        <div className="name bodytitle">{name}</div>
        <div className="text heroparagraph">{text}</div>
        <ButtonPrimaryFilledActive>{buttonPrimaryFilledActiveProps.children}</ButtonPrimaryFilledActive>
        <ButtonPrimaryFilledActive2>{buttonPrimaryFilledActive2Props.children}</ButtonPrimaryFilledActive2>
        <div className="basis ptsans-bold-white-50px">{basis}</div>
      </div>
    </div>
  );
}


function ButtonPrimaryFilledActive(props) {
  const { children, className } = props;

  return (
    <div className={`button-fill-1 ${className || ""}`}>
      <div className="overlap-group2-1">
        <div className="label bodyparagraph">{children}</div>
      </div>
    </div>
  );
}


function ButtonPrimaryFilledActive2(props) {
  const { children, className } = props;

  return (
    <div className={`button-fill-copy ${className || ""}`}>
      <div className="overlap-group4">
        <div className="label-1 bodyparagraph">{children}</div>
      </div>
    </div>
  );
}


function CardPlainDetailExpose(props) {
  const {
    bitmap,
    jobdesk,
    name,
    text,
    detail,
    buttonPrimaryFilledActiveProps,
    buttonPrimaryFilledActive2Props,
  } = props;

  return (
    <div className="card-plain-detail-expose">
      <div className="overlap-group5">
        <div className="x1-1">
          <img className="bitmap-1" src={bitmap} />
        </div>
        <div className="jobdesk-1 ptsans-normal-midnight-blue-13px">{jobdesk}</div>
        <div className="name-1 bodytitle">{name}</div>
        <div className="text-2 heroparagraph">{text}</div>
        <ButtonPrimaryFilledActive className="button-fill">
          {buttonPrimaryFilledActiveProps.children}
        </ButtonPrimaryFilledActive>
        <ButtonPrimaryFilledActive2 className="button-fill-copy-1">
          {buttonPrimaryFilledActive2Props.children}
        </ButtonPrimaryFilledActive2>
        <div className="detail ptsans-bold-white-50px">{detail}</div>
      </div>
    </div>
  );
}


function Zeile(props) {
  const { children } = props;

  return (
    <div className="field">
      <div className="overlap-group3-1 border-1px-midnight-blue">
        <div className="label-2 bodytext">{children}</div>
      </div>
    </div>
  );
}


function ButtonPrimaryFilledActive3(props) {
  const { children, className } = props;

  return (
    <div className={`button-fill-2 ${className || ""}`}>
      <div className="overlap-group2-2">
        <div className="label-3 bodyparagraph">{children}</div>
      </div>
    </div>
  );
}


function ButtonPrimaryFilledActive4(props) {
  const { children } = props;

  return (
    <div className="button-fill-4">
      <div className="overlap-group2-3">
        <p className="label-4 bodyparagraph">{children}</p>
      </div>
    </div>
  );
}


function Zeile2(props) {
  const { children } = props;

  return (
    <div className="field-1">
      <div className="overlap-group3-3 border-1px-midnight-blue">
        <div className="label-5 bodytext">{children}</div>
      </div>
    </div>
  );
}

const buttonPrimaryFilledActiveData = {
    children: "Direktkauf",
};

const buttonPrimaryFilledActive2Data = {
    children: "In den Warenkorb",
};

const CardPlainBasisExposeData = {
    bitmap: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/suchergebnis-bitmap-3357356C-BC7C-4585-ABBD-B781FF5FA1BE.png",
    jobdesk: "Verfügbar seit: 01.01.2021",
    name: "Kosten: 9,95 €",
    text: "Basis-Lärmexpose",
    basis: "Basis",
    buttonPrimaryFilledActiveProps: buttonPrimaryFilledActiveData,
    buttonPrimaryFilledActive2Props: buttonPrimaryFilledActive2Data,
};

const buttonPrimaryFilledActive3Data = {
    children: "Direktkauf",
};

const buttonPrimaryFilledActive22Data = {
    children: "In den Warenkorb",
};
