import React from "react";
import "./style.scss";
import "./style2.scss";
import "./media.scss";
import styled from 'styled-components';
import SearchBar from "../../components/searchbar";
import InlineSearchBar from "../../components/inlinesearchbar"
import FormattedHTMLMessage from '../../components/formattedhtmlmessage'
import Footer from '../../components/footer'

import BackgroundHeader from '../../assets/landing-page.jpg'
import HeaderImgMobile from '../../assets/landing-page-mobile.png'
import FactSheetSamples from '../../assets/fact-sheet-samples.jpg'
import FactSheetMobile from '../../assets/modulemobile.png'
import Button from "../../components/button";
import WieLautBackground from '../../assets/inlinesearch.jpg'
import { useHistoryLangQueryParam } from "../../components/langutils";

function App() {
  return <Startseite {...StartseiteData} />;
}

export default App;

const FactSheetDescription = styled.div`
color: var(--midnight-blue);
line-height: 18px;
min-height: 60px;
width: 902px;
font-family: "PT Sans", Helvetica;
font-size: 14px;
font-style: normal;
font-weight: 400;
letter-spacing: 0px;
`

function Startseite(props) {
  const {
    iconKnopfdruck,
    iconlaerminfo,
    kaufabwicklung,
    partner,
    download,
    wieLautIstEsBei2,
    background,
    imagelaermbelastung_Desktop,
    overlapGroup6,
    prev,
    next,
    oval,
    oval2,
    oval3,
    immoscoutlogo,
    cardPlainAlignLeftProps,
    cardPlainAlignLeft2Props,
    cardPlainAlignLeft3Props,
    buttonPrimaryFilledActive2Props,
    zeileWhiteProps,
    dd_zuWhiteProps,
    cardKundeProps,
    cardKunde2Props,
  } = props;

  const history = useHistoryLangQueryParam();

  return (
    <div className="landingPage">
        <div className="section-showcase" /*style={{backgroundImage:`url(${BackgroundHeader})`}}*/>
          {/* <div className="rectangle"><img style={{ position: "absolute", bottom: "25px", right: "25px" }} src={BackgroundHeader} /></div>
            <img className="partner" src={partner} />
            
            <div style={{ position: "absolute", top: "448px", left: "207px", width: "450px" }}>
              <h1 className="wie-laut-ist-es-bei h1 h2"><FormattedHTMLMessage id="index.howNoisy" /></h1>
              <div className="erfahren-sie-mehr heroparagraph headersubline"><FormattedHTMLMessage id="index.getMoreInfo" /></div>
            </div> */}
            <div className="showcase-titlebox" >
              <h1><FormattedHTMLMessage id="index.howNoisy" /></h1>
              <div className="subtitle"><FormattedHTMLMessage id="index.getMoreInfo" /></div>
            </div>
            <div className="showcase-immo" >
              <img className="partner" src={partner} />
            </div>
            <div className="showcase-image"><img src={BackgroundHeader} /></div>
            <div className="header-mobile"><img   src={HeaderImgMobile} /></div>
            
        </div>

        <div className="suche">
              <div style={{ width: '100%', background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 80%, rgba(237,237,237,1) 80%, rgba(255,255,255,1) 100%)' }}>
                <SearchBar showTitleBox={true} />
              </div>
        </div>



        {/* <div className="lrmexposes">
          <div className="lrmcheck-ihrer-a dachzeile"><FormattedHTMLMessage id="index.checkYourAddress" /></div>
          <div className="textlabel h3"><FormattedHTMLMessage id="index.ourNoiseFactSheets" /></div>
          <div className="sie-planen-einen-imm bodytext"><FormattedHTMLMessage id="index.yourePlanningAPurchase" /></div>
          <div className="overlap-group1">
            <div style={{position: "relative"}}>
              <img className="illustration" src={FactSheetSamples} />
              <FactSheetDescription style={{ top: "445px", position: "absolute", left: "173px", width: "250px" }}>
                <FormattedHTMLMessage id="index.factSheetDescription.basic" />
              </FactSheetDescription>
              <FactSheetDescription style={{ top: "120px", position: "absolute", left: "545px", width: "400px"}}>
                <FormattedHTMLMessage id="index.factSheetDescription.detail" />
              </FactSheetDescription>
            </div>
            <div className="card">
              <div className="overlap-group2">
                <CardPlainAlignLeft title={cardPlainAlignLeftProps.title} text={cardPlainAlignLeftProps.text} />
                <img className="icon-knopfdruck" src={iconKnopfdruck} />
              </div>
              <div className="overlap-group4">
                <img className="icon-laerminfo" src={iconlaerminfo} />
                <CardPlainAlignLeft
                  title={cardPlainAlignLeft2Props.title}
                  text={cardPlainAlignLeft2Props.text}
                  className="x2"
                />
              </div>
              <div className="overlap-group3">
                <img className="kaufabwicklung" src={kaufabwicklung} />
                <CardPlainAlignLeft
                  title={cardPlainAlignLeft3Props.title}
                  text={cardPlainAlignLeft3Props.text}
                  className="x3"
                />
              </div>
            </div>
          </div>
        </div>  */}

        <div className="section-expose">
          <div className="dachzeile"><FormattedHTMLMessage id="index.checkYourAddress" /></div>
          <div className="h3"><FormattedHTMLMessage id="index.ourNoiseFactSheets" /></div>
          <div className="bodytext"><FormattedHTMLMessage id="index.yourePlanningAPurchase" /></div>
          <div className="img-container">
            <div style={{position: "relative"}} className="img-inner-container">
              <img className="illustration" src={FactSheetSamples} />
              <FactSheetDescription style={{ top: "445px", position: "absolute", left: "100px", width: "250px" }}>
                <FormattedHTMLMessage id="index.factSheetDescription.basic" />
              </FactSheetDescription>
              <FactSheetDescription style={{ top: "120px", position: "absolute", left: "480px", width: "400px"}}>
                <FormattedHTMLMessage id="index.factSheetDescription.detail" />
              </FactSheetDescription>
            </div>
          </div>

          <div className="img-container-mobile">
            <div style={{position: "relative"}} className="img-inner-container">
              <img className="fact-sheets-mobile" src={FactSheetMobile} />
            </div>
          </div>

          <div className="row icon-row">
            <div className="col-md-4">
              <img className="icon-knopfdruck expose-icon" src={iconKnopfdruck} />
              <CardPlainAlignLeft title={cardPlainAlignLeftProps.title} text={cardPlainAlignLeftProps.text} />  
            </div>
            <div className="col-md-4">
              <img className="icon-laerminfo expose-icon" src={iconlaerminfo} />
              <CardPlainAlignLeft
                title={cardPlainAlignLeft2Props.title}
                text={cardPlainAlignLeft2Props.text}
                className="x2"
              />
            </div>
            <div className="col-md-4">
              <img className="kaufabwicklung expose-icon" src={kaufabwicklung} />
              <CardPlainAlignLeft
                title={cardPlainAlignLeft3Props.title}
                text={cardPlainAlignLeft3Props.text}
                className="x3"
              />
            </div>
          </div>

        </div>
        
      {/* </div> */}


      {/* { false && 
      <div className="teaser">
        <div className="overlap-group8">
          <div className="background-1">
            <img className="download" src={download} />
          </div>
          <div className="wie-laut-ist-es-bei-1 h1 h2">{wieLautIstEsBei2}</div>
          <ButtonPrimaryFilledActive label={buttonPrimaryFilledActive2Props.label} className="button-fill-1" />
          <ZeileWhite label={zeileWhiteProps.label} />
          <Dd_zuWhite combinedShape={dd_zuWhiteProps.combinedShape} />
        </div>
      </div>
      } */}

      {/* <div className="section-wielaut">
        <div className="section-wielaut-container" style={{ backgroundImage: `url(${WieLautBackground})`}}>
          <h1><FormattedHTMLMessage id="index.howNoisy" /></h1>
          
        </div>
      </div> */}

      <InlineSearchBar />

      <div className="section-krank">
        <div className="row">
          <div className="col-md-6 krank-image-container">
            <div className="lrm-macht-krank dachzeile"><FormattedHTMLMessage id="index.noiseMakesSick" /></div>
            <div className="lrmbelastung-ver h3"><FormattedHTMLMessage id="index.avoidNoise" /></div>
            <img className="image-laermbelastung-desktop" src={imagelaermbelastung_Desktop} />
          </div>
          <div className="col-md-6 text-outer-container" style={{ backgroundImage: `url(${background})`}}>
            <div className="text-container">
              <div className="bodytitle"><FormattedHTMLMessage id="index.cardHealth0.title" /></div>
              <p className="bodyparagraph"><FormattedHTMLMessage id="index.cardHealth0.text" /></p>
            </div>
            <div className="text-container">
              <div className="bodytitle"><FormattedHTMLMessage id="index.cardHealth1.title" /></div>
              <p className="bodyparagraph"><FormattedHTMLMessage id="index.cardHealth1.text" /></p>
            </div>
            <div className="text-container">
              <div className="bodytitle"><FormattedHTMLMessage id="index.cardHealth2.title" /></div>
              <p className="bodyparagraph"><FormattedHTMLMessage id="index.cardHealth2.text" /></p>
            </div>
          </div>
        </div>
      </div>

      {/* <InlineSearchBar />
      <div className="lrmmachtkrank">
        <div className="overlap-group7">
          <img className="background-2" src={background} />
          <p className="text bodyparagraph"><FormattedHTMLMessage id="index.cardHealth0.text" /></p>
          <div className="title bodytitle"><FormattedHTMLMessage id="index.cardHealth0.title" /></div>
          <p className="text-4 bodyparagraph"><FormattedHTMLMessage id="index.cardHealth1.text" /></p>
          <div className="title-2 bodytitle"><FormattedHTMLMessage id="index.cardHealth1.title" /></div>
          <p className="text-5 bodyparagraph"><FormattedHTMLMessage id="index.cardHealth2.text" /></p>
          <div className="title-3 bodytitle"><FormattedHTMLMessage id="index.cardHealth2.title" /></div>
        </div>
        <img className="image-laermbelastung-desktop" src={imagelaermbelastung_Desktop} />
        <div className="lrmbelastung-ver h3"><FormattedHTMLMessage id="index.avoidNoise" /></div>
        <div className="lrm-macht-krank dachzeile"><FormattedHTMLMessage id="index.noiseMakesSick" /></div>
      </div> */}


      {/* { false && 
      <div className="kunden">
        <div className="overlap-group6" style={{ backgroundImage: `url(${overlapGroup6})` }}>
          <div className="textlabel-1 dachzeile"><FormattedHTMLMessage id="index.ourCustomers" /></div>
          <div className="overlap-group1-3">
            <div className="mena-hilft-lrmqu h3"><FormattedHTMLMessage id="index.menaHelps" /></div>
            <div className="testimoni">
              <div className="overlap-group2-3">
                <div className="testimoni-1">
                  <CardKunde text={cardKundeProps.text} name={cardKundeProps.name} jobdesk={cardKundeProps.jobdesk} />
                  <CardKunde
                    text={cardKunde2Props.text}
                    name={cardKunde2Props.name}
                    jobdesk={cardKunde2Props.jobdesk}
                    className="card-plain-testimoni-copy"
                  />
                </div>
                <div className="button">
                  <img className="prev" src={prev} />
                  <img className="next" src={next} />
                </div>
              </div>
              <div className="control">
                <img className="oval" src={oval} />
                <img className="oval-1" src={oval2} />
                <img className="oval-2" src={oval3} />
              </div>
            </div>
          </div>
        </div>
      </div>
     } */}

     <div className="section-immo">
      <img className="immoscout-logo" src={immoscoutlogo} />
      <div className="dachzeile"><FormattedHTMLMessage id="index.ourStrongPartner.title" /></div>
        <div className="h3"><FormattedHTMLMessage id="index.ourStrongPartner.text" /></div>
        { false && <ButtonPrimaryFilledActive2 label={buttonPrimaryFilledActive2Props.label} /> }
        <Button style={{ marginTop: "2em" }} onClick={() => { history.push("/immoscout") }} label={<FormattedHTMLMessage id="index.ourStrongPartner.readMore" />} />
     </div>

      {/* <div className="immoscout">
        <img className="immoscout-logo" src={immoscoutlogo} />
        <div className="unser-starker-partne dachzeile"><FormattedHTMLMessage id="index.ourStrongPartner.title" /></div>
        <div className="mit-mena-gleich-die h3"><FormattedHTMLMessage id="index.ourStrongPartner.text" /></div>
        { false && <ButtonPrimaryFilledActive2 label={buttonPrimaryFilledActive2Props.label} /> }
        <Button style={{ marginTop: "2em" }} onClick={() => { history.push("/immoscout") }} label={<FormattedHTMLMessage id="index.ourStrongPartner.readMore" />} />
      </div> */}


      <Footer />
      
    </div>
  );
}


function CardPlainAlignLeft(props) {
  const { title, text, className } = props;

  return (
    <div className={`x1 ${className || ""}`}>
      <div className="title-1 bodytitle">{title}</div>
      <p className="text-2 bodyparagraph">{text}</p>
    </div>
  );
}


function ButtonPrimaryFilledActive(props) {
  const { label, className } = props;

  return (
    <div className={`button-fill ${className || ""}`}>
      <div className="overlap-group1-1">
        <div className="label-1 bodyparagraph">{label}</div>
      </div>
    </div>
  );
}


function ZeileWhite(props) {
  const { label } = props;

  return (
    <div className="field-1">
      <div className="overlap-group2-2 border-class-1">
        <div className="label-2 bodytext">{label}</div>
      </div>
    </div>
  );
}


function Dd_zuWhite(props) {
  const { combinedShape } = props;

  return (
    <div className="ddzu">
      <img className="combined-shape-1" src={combinedShape} />
    </div>
  );
}


function CardKunde(props) {
  const { text, name, jobdesk, className } = props;

  return (
    <div className={`card-plain-testimoni ${className || ""}`}>
      <div className="text-3 heroparagraph headersubline">{text}</div>
      <div className="name bodytitle">{name}</div>
      <p className="jobdesk bodyparagraph">{jobdesk}</p>
    </div>
  );
}


function ButtonPrimaryFilledActive2(props) {
  const { label } = props;

  return (
    <div className="button-fill-2">
      <div className="overlap-group1-4">
        <div className="label-3 bodyparagraph">{label}</div>
      </div>
    </div>
  );
}


function LOGO(props) {
  const { logo } = props;

  return <div className="logo-1" style={{ backgroundImage: `url(${logo})` }}></div>;
}
const cardPlainAlignLeftData = {
    title: <FormattedHTMLMessage id="index.card0.title" />,
    text: <FormattedHTMLMessage id="index.card0.text" />,
};

const cardPlainAlignLeft2Data = {
    title: <FormattedHTMLMessage id="index.card1.title" />,
    text: <FormattedHTMLMessage id="index.card1.text" />,
};

const cardPlainAlignLeft3Data = {
    title: <FormattedHTMLMessage id="index.card2.title" />,
    text: <FormattedHTMLMessage id="index.card2.text" />,
};

const buttonPrimaryFilledActiveData = {
    label: "Suchen",
};

const zeileData = {
    label: "Strasse, Hausnummer, PLZ",
};

const dd_klapperData = {
    combinedShape: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/suchergebnis-combined-shape-0097E91D-013E-496D-8C3A-F9FFD67C7886@2x.png",
    overlapGroup4: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/startseite-combined-shape-F00CF6BD-DB01-49F9-9612-4A517913F5D7@2x.png",
    mnchen: (<>München<br /></>),
    place: "Berlin",
    place2: "Amsterdam",
};

const topnavData = {
    logo: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/neues-logo-9BF1B9F0-7D64-4F1B-8101-404E8EBED402@2x.png",
    place: "Home",
    text1: "mena und immoscout24",
    neues: "Neues",
    faqs: "FAQs",
    kontaktCopy: "Kontakt",
    meineAuswahl1: "Meine Auswahl (1)",
};

const buttonPrimaryFilledActive2Data = {
    label: "Suchen",
};

const zeileWhiteData = {
    label: "Strasse, Hausnummer, PLZ",
};

const dd_zuWhiteData = {
    combinedShape: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603f4bc43ede8afb3c40f958/img/startseite-combined-shape-40B3BAC5-450F-4398-B23E-DAED6E5658D4@2x.png",
};

const cardKundeData = {
    text: "Hier steht ein Text von einem Kunden zur Bewertung in mehreren Zeilen. ",
    name: "Kunde Vorname Nachname",
    jobdesk: "Nutzt mena zur Bewertung von Hotellagen",
};

const cardKunde2Data = {
    text: "Hier steht ein Text von einem Kunden zur Bewertung in mehreren Zeilen. ",
    name: "Kunde Vorname Nachname",
    jobdesk: "Nutzt mena bei der Suche einer neuen Immobilie",
};

const buttonPrimaryFilledActive22Data = {
    label: "Mehr erfahren",
};

const footerData = {
    footer: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/faqs-fill-1-76ACE4BD-5FE6-4749-8EA1-0F6D0AEF7B06.png",
    menaGmbhTheMeasur: (<>Mena GmbH<br />The Measure of Environmental Analysis<br />Zum Schießwasen 7<br />91710 Gunzenhausen</>),
    berUns: "Über uns",
    kontaktDatenschutz: (<>Kontakt<br />Datenschutz<br />Impressum<br />FAQ</>),
    stayConnected: "Stay Connected",
    facebookLinkedinIn: (<>Facebook<br />Linkedin<br />Instagram</>),
    path: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/startseite-path-8B7356B0-437A-4BAF-8ED6-60EC36C6C796@2x.png",
    overlapGroup3: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/suchergebnis-shape-4EEBFAD1-A863-478A-9457-846EBF579BA4@2x.png",
    shape: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/modulemobile-shape-A0689836-C74E-4A19-BBEA-1358A224B35E@2x.png",
    path2: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/suchergebnis-path-B0F962DD-23D7-4EC9-8630-BCA1C6AEF170@2x.png",
    overlapGroup5: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/moduletablet-path-D104B93D-8DFD-42D0-AE99-9D3BB8DC648D@2x.png",
    path3: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/suchergebnis-path-A0E42D7F-9B0D-4695-802B-4F3B7BE85C75@2x.png",
};

const lOGOData = {
    logo: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/startseite-logo-F0BA5CF8-D023-424B-8873-3AA7DFE4BECF.svg",
};

const StartseiteData = {
    lrmcheckIhrerA: " LÄRMCHECK IHRER ADRESSE",
    text_Label: "Unsere Lärmexposés",
    siePlanenEinenImm: "Sie planen einen Immobilienkauf oder suchen nach einem ruhigen Hotel für den nächsten Städtetrip. Mit unseren Lärmexposés können Sie sich ein umfassendes Bild über die Lärmsituation an Ihrer Wunschadresse machen.",
    illustration: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/startseite-illustration-FD731A54-A48E-46DA-81BC-2AF41A7378DF@2x.png",
    iconKnopfdruck: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/startseite-icon-knopfdruck-40E1B5A0-3AA2-4ED3-8DCE-6420B4C9D029.svg",
    iconlaerminfo: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/startseite-iconlaerminfo-A13252E7-ED12-49E3-BE94-1EC29F3DDFD0.svg",
    kaufabwicklung: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/startseite-kaufabwicklung-CEB55ADF-A277-4256-AD52-A3639900C7EA.svg",
    fill1: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/startseite-fill-1-4072E918-FB40-4D6D-B58E-3901929E8D28.png",
    mask: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/startseite-mask-132C780F-F83C-423F-9E60-6ADA40DD70B8.png",
    bitmap: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/startseite-bitmap-BFCD2F10-6C4F-420F-AA6C-3DBE77C17616.jpg",
    bitmap2: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/startseite-bitmap-74E17E20-21E1-43A0-B3FE-EFC79AF3E167.png",
    stroke1: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/startseite-stroke-1-5249522C-9E12-4F1D-A29C-D48E0EE89D61.png",
    stroke4: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/startseite-stroke-4-C558FB3B-10CF-407A-BE5F-749AB849DF7A.png",
    stroke7: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/startseite-stroke-7-F8C940A6-6E99-4305-84FA-43D9E83A6AF6.png",
    map: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/startseite-map-377B71CE-CEE7-4401-82F0-7C6C8CF8828D@2x.png",
    partner: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/startseite-partner-58D44B68-4778-413D-B6BD-E55FB2ABB546.png",
    rectangle: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/startseite-rectangle-BDEB598B-AE90-43C4-B96B-72199E05DD50.png",
    erfahrenSieMehr: (<>Erfahren Sie mehr über die Lärmbelastung in Ihrer Wohngegend. Bestellen Sie Ihr Lärmexpose direkt hier. Gleich Metropole auswählen und Adresse suchen. <br /><br /></>),
    wieLautIstEsBei: (<>Wie laut ist es bei <br />Ihnen Zuhause?</>),
    download: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/neues-download-55AE9529-E4CA-4229-A130-1A4D94F895DC.jpg",
    wieLautIstEsBei2: (<>Wie laut ist es bei <br />Ihnen Zuhause?</>),
    background: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/startseite-background-38265C68-0981-4435-830E-EF2D59C3D0E9.png",
    text: "Meinungsumfragen zeigen, dass der Straßenverkehrslärm als besonders störend empfunden wird. Nach Angaben des Umweltbundesamtes geben 54 % der befragten Bürger an, sich durch Straßenverkehrslärm belästigt zu fühlen, 20 % fühlen sich durch den Straßenverkehrslärm sogar stark beeinträchtigt. ",
    title: "Lärm stört das tägliche Leben",
    text2: "Lärm wirkt jedoch nicht nur störend, sondern kann auch gesundheitliche Folgen nach sich ziehen. Neben Konzentrations-, Lern-, und Schlafstörungen kann es bei dauerhaft erhöhtem Lärmpegel sogar zu Herz-Kreislauf-Erkrankungen kommen.",
    title2: "Lärm beeinträchtigt die Gesundheit",
    text3: (<>Alle Mitgliedsstaaten der EU sind dazu verpflichtet den Umgebungslärm in Ballungsgebieten, sowie an Straßen mit erhöhtem Verkehrsaufkommen zu berechnen und der EU zu melden. Diese Daten sind in aller Regel für den Nutzer nicht einfach zu lesen. Mena bietet hier eine adressscharfe Aufbereitung und Darstellung des Umgebungslärms nach anerkannten fachlichen Kriterien. Einfach verständlich.<br /><br /></>),
    title3: "Lärmquellen erkennen und vermeiden",
    imagelaermbelastung_Desktop: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/startseite-imagelaermbelastungdesktop-6C90AAF4-8CF4-423E-A7E6-299BD8AA9710.png",
    lrmbelastungVer: (<>Lärmbelastung vermeiden <br />und gesund leben</>),
    lrmMachtKrank: "LÄRM MACHT KRANK",
    overlapGroup6: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/startseite-gradient-36F40D3D-5072-4092-9F26-74BFB181D286.png",
    text_Label2: "UNSERE KUNDEN SIND ÜBERZEUGT",
    menaHilftLrmqu: (<>Mena hilft Lärmquellen zu idenitifizieren <br />und die Lebensqualität zu verbessern.</>),
    prev: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/menaimmoscout-prev-2314FE5F-674B-44AD-B234-3D6408D22B5A@2x.png",
    next: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/modulemobile-next-E233BCDF-279A-4FA9-B61D-317384E28576@2x.png",
    oval: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/menaimmoscout-oval-E93E6E8A-2265-465B-ACA3-C24D61AF74A5@2x.png",
    oval2: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/menaimmoscout-oval-52440558-3537-4EE1-B5FE-C100AF8CCD16@2x.png",
    oval3: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/menaimmoscout-oval-E93E6E8A-2265-465B-ACA3-C24D61AF74A5@2x.png",
    immoscoutlogo: "https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/startseite-immoscoutlogo-2889A2A9-956A-4752-B5C3-DD7F63A36D3A.png",
    unserStarkerPartne: "UNSER STARKER PARTNER",
    mitMenaGleichDie: (<>Mit mena gleich die Lärmdaten <br />checken für die Traumimmobilie.</>),
    copyright2021: "Copyright© 2021. Mena GmbH",
    cardPlainAlignLeftProps: cardPlainAlignLeftData,
    cardPlainAlignLeft2Props: cardPlainAlignLeft2Data,
    cardPlainAlignLeft3Props: cardPlainAlignLeft3Data,
    buttonPrimaryFilledActiveProps: buttonPrimaryFilledActiveData,
    zeileProps: zeileData,
    dd_klapperProps: dd_klapperData,
    topnavProps: topnavData,
    buttonPrimaryFilledActive2Props: buttonPrimaryFilledActive22Data,
    zeileWhiteProps: zeileWhiteData,
    dd_zuWhiteProps: dd_zuWhiteData,
    cardKundeProps: cardKundeData,
    cardKunde2Props: cardKunde2Data,
    footerProps: footerData,
    lOGOProps: lOGOData,
};

