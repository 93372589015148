import { useEffect, useState } from "react"
import ProductService from '../../service/product-service'
import App from ".";
import { useLocation } from "react-router-dom";
import PageWrapper from "../../components/page-wrapper";

export default function() {
  const [searchResults, setSearchResults] = useState(null)

  const location = useLocation();

  useEffect(() => {
    (async () => {
      const searchParams = new URLSearchParams(location.search);
      const products = await ProductService.searchProducts(searchParams.get("c"), searchParams.get("a"))
      console.log("Products", products);
      setSearchResults(products)
    })()
  }, [location])

  return (
    <PageWrapper>
      <App searchResults={searchResults} />
    </PageWrapper>
  )
}