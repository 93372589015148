import React from 'react';
import styled from 'styled-components';

import { Column, Row, Filler } from '../../components/base';
import Footer from '../../components/footer';
import FormattedHTMLMessage from '../../components/formattedhtmlmessage';

import "./style.scss";

import BackgroundImage from '../../assets/news.jpg';
import Jumbotron from '../../components/jumbotron';
import SectionHead from '../../components/sectionhead';
import { useIntl } from 'react-intl';

const Text = styled.div`
  margin-top: 0.5em;
  background-color: transparent;
  color: var(--midnight-blue);
  font-weight: 400;
  height: auto;
  left: 0px;
  line-height: 30px;
  text-align: left;
  font-family: 'PT Sans', Helvetica, Arial, serif;
  font-size: 18px;
  font-style: normal;
  letter-spacing: 0.00px;

  strong {
    font-weight: 700;
  }

  i {
    font-style: italic;
  }

  p {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  ul {
    margin-left: 1em;
    list-style-type: initial;
  }

  ol {
    margin-left: 1em;
    list-style-type: decimal;
  }
`

function App() {  
  const intl = useIntl()
  let html = intl.formatMessage({id: "imprint.content"})

  /*return (
    <div className="suchergebnis">
      <Jumbotron title={<FormattedHTMLMessage id="imprint.title" />} img={BackgroundImage} />
      <Row style={{ minWidth: "80%" }}>
        <Filler />
        <Column style={{width: '78%', paddingTop: '3em', paddingBottom: '3em'}}>
            <SectionHead><FormattedHTMLMessage id="imprint.header" /></SectionHead>

            <Text>
            <div dangerouslySetInnerHTML={{__html: html}} />
            </Text>
        </Column>
        <Filler />
      </Row>
      
      <Footer />
    </div>
  );*/

  return (
    <div className="imprint-container">
      <Jumbotron title={<FormattedHTMLMessage id="imprint.title" />} img={BackgroundImage} />

      <div className="content-container">
        <SectionHead><FormattedHTMLMessage id="imprint.header" /></SectionHead>

        <Text>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Text>
      </div>
      <Footer />
    </div>
  );
}

export default App;