import styled from 'styled-components';

const ButtonElement = styled.button`
  cursor: pointer;
  font-size: 18px;
  align-items: flex-end;
  background-color: rgb(0, 154, 180);
  border: none;
  border-radius: 0.3em;
  display: flex;
  padding-top: calc(0.7em + 1px);
  padding-bottom: calc(0.7em + 1px);
  padding-left: 1em;
  padding-right: 1em;
  min-width: 8em;
  box-shadow: none;
  color: white;

  div {
    margin-left: auto;
    margin-right: auto;
  }

  &:focus {
    outline: none;
  }

  &.disabled {
    opacity: 0.5;
  }
`;

export default function Button({label, onClick, disabled, style = {} }) {
  return (
    <ButtonElement onClick={onClick} className={[disabled ? "disabled" : null]} disabled={disabled} style={style}>
      <div>{label}</div>
    </ButtonElement>
  );
}