import styled from 'styled-components';

import ChevronLight from '../assets/chevron.png'
import ChevronDark from '../assets/chevron-dark.png'
import ArrowLight from '../assets/arrow.png'
import ArrowDark from '../assets/arrow-dark.png'
import { useState } from 'react';
import { Column } from './base';
import InputErrMsg from './inputerrmsg';

import './dropdown-hierachical.scss';

const TextBase = styled.span`
  font-family: "PT Sans", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`

const TextLight = styled(TextBase)`
  color: rgb(0, 53, 95);
`

const TextDark = styled(TextBase)`
  color: white;
`

const DropdownWrapper = styled.div`
  font-size: 18px;
  padding-top: calc(0.7em + 1px);
  padding-bottom: calc(0.7em + 1px);
  position: relative;
  padding-left: 0.7em;
  padding-right: 0.7em;
`

const DropdownBackgroundBase = styled.div`
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 0.5em;
  border: 1px solid white;
`

const DropdownInner = styled.div`
  max-height: 10em;
  overflow-y: scroll;

  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 1em;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

const DropdownBackgroundLight = styled(DropdownBackgroundBase)`
  background-color: #edf5f7;
`

const DropdownBackgroundDark = styled(DropdownBackgroundBase)`
  border: 1px solid white;
`

const DropdownElementBase = styled.li`
  cursor: pointer;

  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  line-height: 30px;

  font-family: "PT Sans", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  transition: background-color 0.3s, color 0.3s;
`

const DropdownElementLight = styled(DropdownElementBase)`
  color: rgb(0, 53, 95);

  &:hover {
    background-color: rgb(0, 53, 95);
    color: #edf5f7;
  }
`

const DropdownElementDark = styled(DropdownElementBase)`
  color: white;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`

export default function DropdownHierarchical({items, placeholder, value, onChange, errors, theme, fontStyle = {}, ...rest}) {
  const [expanded, setExpanded] = useState(false);

  //const matching = items.filter(i => i.value === value)
  //const matching = items.map(item => item.cities.filter(city => city.value === value).cities)

  //needs to get an array with one elem out of an array of objects on match
  const matching = items.reduce(
    (acc, current) => {
        if(acc != null) return acc; 
        const match = current.cities.filter(
            city => {
                return city.value === value
            }); 
        if(match.length != 0) return match; 
        return null;
  },null)
  
  const selected = (matching && matching[0]) || null;

  const Chevron = theme === 'dark' ? ChevronDark : ChevronLight;
  const Arrow = ArrowLight;
  const Text = theme === 'dark' ? TextDark : TextLight;
  const DropdownBackground = DropdownBackgroundLight;
  const DropdownElement = DropdownElementLight;

  return (
    <Column {...rest}>
      <div className='dropdownWrapper' onMouseOver={() => setExpanded(true)} onMouseLeave={() => setExpanded(false)}>
        <div style={{ flexDirection: 'row', marginBottom: '0.25em' }}>
          <img style={{ height: '12px', transform: expanded ? '' : 'rotate(-90deg)', transition: 'transform 0.5s' }} src={Chevron} />&nbsp;<Text style={{ fontSize: '18px', ...fontStyle }}>{ (selected && selected.label) || placeholder }</Text>
        </div>
        {expanded &&
          <div className="dropdownBox" >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 1 }} />
              <img src={Arrow} style={{ height: '14px' }} />
              <div style={{ flex: 1 }} />
            </div>
            <DropdownBackground>
              <DropdownInner>
                <ul className='outerList'>
                  {items.map(
                    countryElem =>  <DropdownWrapperElem 
                                        nationcode={countryElem.country} 
                                        cityList={countryElem.cities}
                                        onChange={onChange}
                                        setExpanded={setExpanded}>
                                        </DropdownWrapperElem>)}
                </ul>
        
              </DropdownInner>
            </DropdownBackground>
          </div>
        }
      </div>
      
      <InputErrMsg errors={errors} />
    </Column>
  );
}

function DropdownWrapperElem(props) {

  const {nationcode, cityList, onChange, setExpanded} = props;
  const DropdownElement = DropdownElementLight;

  return (
    <li>
      {/* <div>
        <img src={require(`../assets/flag-${nationcode}.png`).default} width="40" />
      </div> */}
      <ul className='innerList'>
        <div>
        
        {cityList.map(i => 
          <div className='row'>
          <img src={require(`../assets/flag-${nationcode}.png`).default} width="30" className='col-5'/>  
          <DropdownElement className='innerListElem col-7' onClick={() => onChange(i.value) || setExpanded(false)}>{i.label}</DropdownElement>
          </div>)}
        </div>
      </ul>
      <hr />
    </li>
  );
}