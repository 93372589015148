import { useMemo } from 'react'
import { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import cookieContentReducer, { SET_COOKIE_CONSENT } from '../redux/cookie-consent'
import { Column, Filler, Row } from './base'
import Button from './button'
import './cookie-consent.scss'


const Backdrop = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 99999;
`

const Window = styled.div`
  font-family: "PT Sans",Helvetica;
  font-size: 18px;
  color: var(--midnight-blue);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  padding: 1em;
  background-color: white;
  border: 2px solid var(--midnight-blue);
  max-width: 400px;

  position: fixed;
  left:0;
  right:0;
  margin: 5% auto;

  p {
    margin-top: 0.5em;
  }

  table td {
    padding: 0.5em;
  }
  
`

export default function CookieConsent() {
  
  const intl = useIntl()

  const text = intl.formatMessage({ id: 'base.cookieconsent.text' })
  
  const dispatch = useDispatch()
  const cookieConsentSaved = useSelector(({ cookieConsent }) => cookieConsent)

  let cookieConsent = useMemo(() => {

    return JSON.stringify(cookieConsentSaved) !== '{}' ? cookieConsentSaved : {
      essential: true,
      statistics: false,
      marketing: false,
    }

  }, [cookieConsentSaved])

  const setCookieConsentAttr = (attr, value) => {
    cookieConsent = {...cookieConsent, [attr]: value}
  }

  const saveSettings = () => {
    const newCookieConsent = {...cookieConsent, saved: true}
    dispatch({ type: SET_COOKIE_CONSENT, cookieConsent: newCookieConsent })
  }

  const onDeclineOptionalCookies = () => {
    cookieConsent = {
      essential: true,
      statistics: false,
      marketing: false,
    } 
    saveSettings();
  }

  const onAcceptAllCookies = () => {
    cookieConsent = {
      essential: true,
      statistics: true,
      marketing: true,
    } 
    saveSettings();
  }

  return (
    <>
      {/* { (!cookieConsent || !cookieConsent.saved) &&
        <Backdrop>
          <Column style={{ height: '100vh', width: '100vw' }}>
            <Filler />
            <Row>
              <Filler />
                <Window>
                  <div className='cookie-info-text' dangerouslySetInnerHTML={{ __html: text }}></div>
                  
                  <div className='row cookie-checkboxes'>
                    <div className='col-4'>
                      <input type="checkbox"  disabled={true} checked={cookieConsent.essential} onChange={ evt => setCookieConsentAttr("essential", evt.target.checked) } /><FormattedMessage id="base.cookieconsent.option1" />
                    </div>
                    <div className='col-4'>
                    <input type="checkbox"  onChange={ evt => setCookieConsentAttr("statistics", evt.target.checked) } /><FormattedMessage id="base.cookieconsent.option2" />
                    </div>
                    <div className='col-4'>
                    <input type="checkbox"  onChange={ evt => setCookieConsentAttr("marketing", evt.target.checked) } /><FormattedMessage id="base.cookieconsent.option3" />
                    </div>
                  </div>
                  
                  <div className='row cookie-button-container'>
                    <Button label={<FormattedMessage id="base.cookieconsent.save" /> } onClick={saveSettings} />
                  </div>
                  <div className='row cookie-button-container'>
                    <Button label={<FormattedMessage id="base.cookieconsent.acceptAll" /> } onClick={onAcceptAllCookies} />
                  </div>
                  <div className='row cookie-button-container'>
                    <Button label={<FormattedMessage id="base.cookieconsent.decline" /> } onClick={onDeclineOptionalCookies} />
                  </div>
                </Window>
              <Filler />
            </Row>
            <Filler />
          </Column>
        </Backdrop>
      } */}

      {(!cookieConsent || !cookieConsent.saved) &&


        <Backdrop>
          <Window>
            <div className='cookie-info-text' dangerouslySetInnerHTML={{ __html: text }}></div>

            <div className='row cookie-checkboxes'>
              <div className='col-4'>
                <input type="checkbox" disabled={true} checked={cookieConsent.essential} onChange={evt => setCookieConsentAttr("essential", evt.target.checked)} /><FormattedMessage id="base.cookieconsent.option1" />
              </div>
              <div className='col-4'>
                <input type="checkbox" onChange={evt => setCookieConsentAttr("statistics", evt.target.checked)} /><FormattedMessage id="base.cookieconsent.option2" />
              </div>
              <div className='col-4'>
                <input type="checkbox" onChange={evt => setCookieConsentAttr("marketing", evt.target.checked)} /><FormattedMessage id="base.cookieconsent.option3" />
              </div>
            </div>

            <div className='row cookie-button-container'>
              <Button label={<FormattedMessage id="base.cookieconsent.save" />} onClick={saveSettings} />
            </div>
            <div className='row cookie-button-container'>
              <Button label={<FormattedMessage id="base.cookieconsent.acceptAll" />} onClick={onAcceptAllCookies} />
            </div>
            <div className='row cookie-button-container'>
              <Button label={<FormattedMessage id="base.cookieconsent.decline" />} onClick={onDeclineOptionalCookies} />
            </div>
          </Window>
        </Backdrop>
      }
    </>
  )
}