import { get } from 'lodash';
import { useCallback } from 'react';
import styled from 'styled-components';
import { Column } from './base';
import InputErrMsg from './inputerrmsg';

const InputStyledBase = styled.input`
  font-size: 18px;
  padding: 0.7em;
  
  &.error {
    border: 1px solid red;
  }

  border-radius: 0.3em;
  box-shadow: none;
  text-align: center;
 
  &:focus {
    outline: none;
  }
`

const InputStyledLight = styled(InputStyledBase)`
  border: 1px solid rgb(0, 53, 95);
  color: rgb(0, 53, 95);
`

const InputStyledDark = styled(InputStyledBase)`
  border: 1px solid white;
  color: white;
  background: none;

`


export default function InputField({placeholder, alignment = "left", errors, value, onChange, onEnterPress, theme, style = {}, ...rest}) {
  const onKeyUp = useCallback(evt => {
    if(evt.keyCode === 13 && onEnterPress) {
      onEnterPress()
    }
  }, [onEnterPress])

  const InputStyled = theme === 'dark' ? InputStyledDark : InputStyledLight;

  return (
    <Column {...rest} style={style}>
      <InputStyled placeholder={placeholder} style={{ textAlign: alignment }} value={value} onChange={onChange} onKeyUp={onKeyUp}></InputStyled>
      <InputErrMsg errors={errors} />
    </Column>
  );
}