import React from 'react'
import styled from 'styled-components'

const InputErrMsg = styled.span`
  color: red;
  font-family: "PT Sans", Helvetica;
  margin-top: 2px;
  font-size: 16px;
`

export default function({errors}) {
  return <InputErrMsg>{ errors }</InputErrMsg>
}