import styled from 'styled-components'

const SectionHead = styled.div`
  color: rgba(0, 53, 95, 1);
  font-family: "PT Sans", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 2em;
  margin-top: 4em;
  border-bottom: 1px solid rgba(0, 53, 95, 1);
`

export default SectionHead