import { get } from 'lodash'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from '@redux-devtools/extension';
import cookieContentReducer from './cookie-consent'
import paymentDataReducer from './payment-data-reducer'
import shoppingCartReducer from './shopping-cart-reducer'

const combined = combineReducers({ shoppingCart: shoppingCartReducer, paymentData: paymentDataReducer, cookieConsent: cookieContentReducer })

const existingData = JSON.parse(localStorage.getItem("store")) || {}

const store = createStore(
  combined, 
  existingData,
  composeWithDevTools()
)

store.subscribe(() => {
  const state = JSON.parse(JSON.stringify(store.getState()))

  if(!get(state, "cookieConsent.saved" || !get(state, "cookieConsent.functionalData"))) {
    return
  }

  /*if(!get(state, "cookieConsent.shoppingCart")) {
    delete state.shoppingCart
    console.log("Deleted shopping cart", state)
  }

  if(!get(state, "cookieConsent.addressData")) {
    delete state.paymentData
    console.log("Deleted payment data", state)
  }

  if(!get(state, "cookieConsent.paymentData") && state.paymentData) {
    delete state.paymentData.iban
    delete state.paymentData.accountOwner
    delete state.paymentData.cardNumber
    delete state.paymentData.cardHolder
    delete state.paymentData.validUntil
  }*/

  if(get(state, "paymentData.cvv")) {
    delete state.paymentData.cvv
  }

  localStorage.setItem('store', JSON.stringify(state))
})

export default store