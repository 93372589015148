import React, { useState } from 'react';
import styled from 'styled-components'

import Chevron from '../assets/chevron.png'

const CollapseWrapper = styled.div`
  margin-bottom: 2em;
`

const CollapseHead = styled.div`
  background-color: transparent;
  color: var(--midnight-blue);
  font-weight: 400;
  height: auto;
  line-height: 1.5em;
  text-align: left;
  width: 100%;
  font-family: 'PT Sans', Helvetica, Arial, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.00px;
  cursor: pointer;
  padding-left: 1.3em;
  text-indent: -1.3em;
`;

const CollapseBody = styled.div`
  margin-top: 0.5em;
  background-color: transparent;
  color: var(--midnight-blue);
  font-weight: 400;
  height: auto;
  left: 0px;
  line-height: 30px;
  text-align: left;
  font-family: 'PT Sans', Helvetica, Arial, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.00px;
  overflow: hidden;
  max-height: 20em;
  transition: max-height 0.5s;

  &.collapsed {
    max-height: 0em;
  }
`

export default function Collapse({ head, body }) {
  const [collapsed, setCollapsed] = useState(true)

  return <CollapseWrapper>
    <CollapseHead onClick={() => setCollapsed(!collapsed)}><img style={{ transform: collapsed ? 'rotate(-90deg)' : '', transition: 'transform 0.5s', marginRight: '0.5em', width: '0.75em' }} src={Chevron} />{head}</CollapseHead>
    <CollapseBody className={[collapsed ? 'collapsed' : null]}>
    {body}
    </CollapseBody>
  </CollapseWrapper>
}