import { get } from 'lodash';
import { useCallback } from 'react';
import styled from 'styled-components';
import { Column } from './base';
import InputErrMsg from './inputerrmsg';

const TextAreaStyled = styled.textarea`
  font-family: 'PT Sans', Helvetica, Arial, serif;
  font-size: 18px;
  padding: 0.7em;
  border: 1px solid rgb(0, 53, 95);
  color: rgb(0, 53, 95);
  height: 100%;
  
  &.error {
    border: 1px solid red;
  }

  border-radius: 0.3em;
  box-shadow: none;
  text-align: center;
 
  &:focus {
    outline: none;
  }
`

export default function InputArea({placeholder, alignment = "left", errors, value, onChange, onEnterPress, theme, style = {}, ...rest}) {
  return (
    <Column {...rest} style={style}>
      <TextAreaStyled placeholder={placeholder} style={{ textAlign: alignment }} value={value} onChange={onChange}></TextAreaStyled>
      <InputErrMsg errors={errors} />
    </Column>
  );
}