import React, { useMemo } from 'react';
import styled from 'styled-components';
import useAxios from 'axios-hooks'

import { Column, Row, Filler } from '../../components/base';
import Footer from '../../components/footer';
import FormattedHTMLMessage from '../../components/formattedhtmlmessage';

import "./style.scss";
import "./style2.scss";

import BackgroundImage from '../../assets/news.jpg';
import Jumbotron from '../../components/jumbotron';
import Collapse from '../../components/collapse';
import SectionHead from '../../components/sectionhead';
import { useLang } from '../../components/langutils';
import { useIntl } from 'react-intl';

const ResetListStyle = styled.div`
  ul {
    margin-left: 1em;
    list-style-type: initial;
  }

  ol {
    margin-left: 1em;
    list-style-type: decimal;
  }
`

const Text = styled.div`
  font-family: "PT Sans", Helvetica;
  margin-top: 0.5em;
  background-color: transparent;
  color: var(--midnight-blue);
  font-weight: 400;
  height: auto;
  left: 0px;
  font-size: 24px;
  line-height: 30px;
`

function App() { 

  const lang = useLang()
  const intl = useIntl()

  const filter = {
    lang: { c: 'EQ', v: lang }
  }

  const filterStr = JSON.stringify(filter)

  const [{ data, loading: getLoading, error: getError }] = useAxios(
    `/api/faq/section?f=${encodeURIComponent(filterStr)}`
  )

  /*return (
    <div className="suchergebnis">
      <Jumbotron title={<FormattedHTMLMessage id="faqs.title" />} img={BackgroundImage} />
      <Row style={{ minWidth: "80%" }}>
        <Filler />
        <Column style={{width: '78%', paddingTop: '3em', paddingBottom: '3em'}}>
          <ResetListStyle>
            {
              data && data.map(section => (
                <>
                  <SectionHead>{section.title}</SectionHead>
                  {section.faqs.map(faq => (
                    <Collapse head={faq.question} body={<div dangerouslySetInnerHTML={{__html: faq.answer}}></div>} />
                  ))}
                </>
              ))
            }
          </ResetListStyle>

          <Text dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "faqs.moreQuestions" })}} />
        </Column>
        <Filler />
      </Row>
      
      <Footer />
    </div>
  );*/

  return (
    <div>
      <div className="section-news">
        <Jumbotron title={<FormattedHTMLMessage id="faqs.title" />} img={BackgroundImage} />
        
        <div className="faq-container">
          <ResetListStyle>
              {
                data && data.map(section => (
                  <>
                    <SectionHead>{section.title}</SectionHead>
                    {section.faqs.map(faq => (
                      <Collapse head={faq.question} body={<div dangerouslySetInnerHTML={{__html: faq.answer}}></div>} />
                    ))}
                  </>
                ))
              }
            </ResetListStyle>

            <div className="hint" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "faqs.moreQuestions" })}}></div>
        </div>
          
      </div>
      <Footer />
    </div>
  );
}

export default App;