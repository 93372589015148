import React, { useEffect, useState } from 'react';


export default function Sitemap() {

    /*const [data, setData] = useState(null); 

    useEffect(async () => {
        const res = await fetch("sitemap.xml");
        setData(await res.text());
        console.log(data);
    })*/

    return (
        
        <div>
            <a download="sitemap" target="_blank" href="/sitemap.xml">
                Sitemap
            </a>
        </div>
    );
}

