import React from 'react';
import styled from 'styled-components';

import { Column, Row, Filler } from '../../components/base';
import Footer from '../../components/footer';
import FormattedHTMLMessage from '../../components/formattedhtmlmessage'

import "./style.scss";
import "./style2.scss";

import Jumbotron from '../../components/jumbotron';
import { useHistoryLangQueryParam, useLang } from '../../components/langutils';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import BackgroundImage from '../../assets/news.jpg';
import NewsImage from '../../assets/news-img.jpg';
import OtherArticleImage from '../../assets/other-article.jpg';

const Article = styled.div`
  margin-top: 5em;
`

const AuthorName = styled.div`
  color: var(--bondi-blue-2);
  font-weight: 700;
  line-height: 19px;
  min-height: 17px;
  text-align: center;
  white-space: nowrap;

  font-family: 'PT Sans', Helvetica, Arial, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 10.00px;
  text-transform: uppercase;
`

const Headline = styled.div`
  margin-top: 0.5em;
  margin-bottom: 1em;
  background-color: transparent;
  color: var(--midnight-blue);
  font-weight: 400;
  height: auto;
  left: 0px;
  line-height: 55px;
  text-align: center;

  font-family: 'PT Sans', Helvetica, Arial, serif;
  font-size: 42px;
  font-style: normal;
`

const Text = styled.div`
  background-color: transparent;
  color: var(--midnight-blue);
  font-weight: 400;
  height: auto;
  left: 2px;
  line-height: 30px;
  text-align: left;

  font-family: 'PT Sans', Helvetica, Arial, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.00px;
`

const articles = {
  de: [
    {
      "path": "vdi-podcast-welche-auswirkungen-hat-laerm-auf-uns",
      "author": "Sandra Lanig",
      "title": "VDI-Podcast: Welche Auswirkungen hat Lärm auf uns?",
      "date": "29.04.2021",
      "content": "<p>Am 28. April 2021 fand der 24. Tag gegen Lärm statt. Diesem Thema widmet sich eine Podcast-Episode (<a href=\"https://technikaufsohr.podigee.io/45-tag-gegen-laerm\">https://technikaufsohr.podigee.io/45-tag-gegen-laerm</a> ) des VDIs (Verein Deutscher Ingenieure e.V.), die der Frage nachgeht, welche Auswirkungen Lärm auf die Umwelt und den Menschen hat.</p><p><a href=\"https://www.vdi.de/news/detail/welche-auswirkungen-hat-laerm-auf-uns\">https://www.vdi.de/news/detail/welche-auswirkungen-hat-laerm-auf-uns</a></p>",
      "imagePreview": "",
    },
    {
      "path": "arbeiten-der-europaeischen-umweltagentur",
      "author": "Sandra Lanig",
      "title": "Arbeiten der Europäische Umweltagentur (EUA) zum Thema Gesundheit und Umwelt, einschließlich Luftverschmutzung und Lärmbelästigung",
      "date": "15.12.2020",
      "content": "<p>Luftverschmutzung, Lärmbelästigung und die Folgen des Klimawandels sind wesentliche Risiken für die allgemeine Gesundheit und das Wohlbefinden des Menschen in Europa – ein Experteninterview mit Sachverständigen der EUA.</p><p><a href=\"https://www.eea.europa.eu/de/articles/gesundheit-und-umwelt-einschliesslich-luftverschmutzung\">https://www.eea.europa.eu/de/articles/gesundheit-und-umwelt-einschliesslich-luftverschmutzung</a></p>"
    },
    {
      "path": "neue-leitlinien-der-who-für-laermbelastung-veroeffentlicht",
      "author": "Sandra Lanig",
      "title": "Neue Leitlinien der WHO für Lärmbelastung veröffentlicht",
      "date": "01.12.2018",
      "content": "<p>Lärm ist ein wichtiges Thema im Bereich der öffentlichen Gesundheit und hat negative Auswirkungen auf die menschliche Gesundheit und das Wohlbefinden und wird in zunehmendem Maße zu einem Problem. Das WHO-Regionalbüro für Europa hat Leitlinien insbesondere zum Schutz der menschlichen Gesundheit hierzu entwickelt.</p><p><a href=\"https://www.euro.who.int/de/media-centre/sections/press-releases/2018/press-information-note-on-the-launch-of-the-who-environmental-noise-guidelines-for-the-european-region\">https://www.euro.who.int/de/media-centre/sections/press-releases/2018/press-information-note-on-the-launch-of-the-who-environmental-noise-guidelines-for-the-european-region</a></p>"
    }
  ],
  en: [
    {
      "path": "vdi-podcast-how-does-noise-affect-us",
      "author": "Sandra Lanig",
      "title": "VDI-Podcast: How does noise affect us?",
      "date": "2021/04/29",
      "content": "<p>The 24th “Day Against Noise” was held on 28 April 2021. A VDI podcast (in German - <a href=\"https://technikaufsohr.podigee.io/45-tag-gegen-laerm\">https://technikaufsohr.podigee.io/45-tag-gegen-laerm</a>) is dedicated to this topic and explores the question of what effects noise has on the environment and on people. </p><p><a href=\"https://www.vdi.de/news/detail/welche-auswirkungen-hat-laerm-auf-uns\">https://www.vdi.de/news/detail/welche-auswirkungen-hat-laerm-auf-uns</a></p>",
      "imagePreview": "",
    },
    {
      "path": "european-environment-agency-eea-work-on-health-and-the-environment",
      "author": "Sandra Lanig",
      "title": "European Environment Agency (EEA) work on health and the environment, including air and noise pollution",
      "date": "2020/12/15",
      "content": "<p>Air pollution, noise pollution and the impacts of climate change are key risks to the everyday health and well-being of Europeans - an expert interview with EEA experts.</p><p><a href=\"https://www.eea.europa.eu/articles/health-and-environment-including-air\">https://www.eea.europa.eu/articles/health-and-environment-including-air</a></p>",
      "imagePreview": "",
    },
    {
      "path": "new-who-noise-guidelines-for-europe-released",
      "author": "Sandra Lanig",
      "title": "New WHO noise guidelines for Europe released",
      "date": "2018/12/01",
      "content": "<p>Noise is an important public health issue. It has negative impacts on human health and well-being and is a growing concern. The WHO Regional Office for Europe has developed these guidelines, based on the growing understanding of these health impacts of exposure to environmental noise. </p><p><a href=\"https://www.euro.who.int/en/media-centre/sections/press-releases/2018/press-information-note-on-the-launch-of-the-who-environmental-noise-guidelines-for-the-european-region \">https://www.euro.who.int/en/media-centre/sections/press-releases/2018/press-information-note-on-the-launch-of-the-who-environmental-noise-guidelines-for-the-european-region </a></p>",
      "imagePreview": "",
    }
  ]
}


function App() {  
  const { path } = useParams();

  const lang = useLang()
  const history = useHistoryLangQueryParam();

  console.log("News lang", lang)

  if(!path) {
    const newPath = articles[lang][0].path;
    history.replace(`/news/${encodeURIComponent(newPath)}`)
    return <></>
  }

  const currentArticle = articles[lang].filter(a => a.path === path)[0]

  if(!currentArticle) {
    const newPath = articles[lang][0].path;
    console.log("Redirecting to", newPath)
    history.replace(`/news/${encodeURIComponent(newPath)}`)
    return <></>
  }

  /*return (
    <div className="suchergebnis">
      <Jumbotron title={<FormattedHTMLMessage id="news.title" />} img={BackgroundImage} />
      <Row style={{ minWidth: "80%" }}>
        <Filler />
        <Column style={{width: '78%'}}>
            <Article>
              <AuthorName>{currentArticle.author}</AuthorName>
              <Headline>
                {currentArticle.title}
              </Headline>
              <Text dangerouslySetInnerHTML={{__html: currentArticle.content}} />
              <div style={{ textAlign: 'center'}}>
              <img src={NewsImage} style={{ height: '528px', width: '353px', marginTop: '2em', marginBottom: '2em' }}/>
              </div>
          </Article>

          <Headline style={{ marginTop: '3em' }}><FormattedMessage id="news.otherArticles" /></Headline>

          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {
               articles[lang].filter(a => a.path !== path).map(a => 
                <OtherArticle title={a.title} path={a.path} date={a.date} author={a.author} />
               )
            }
          </div>
          
        </Column>
        <Filler />
      </Row>
      
      <Footer />
    </div>
  );*/

  return (
    <div>
      <Jumbotron title={<FormattedHTMLMessage id="news.title" />} img={BackgroundImage} />

      <div className="article-container">
        <Article>
          <AuthorName>{currentArticle.author}</AuthorName>
          <Headline>
            {currentArticle.title}
          </Headline>
          <Text dangerouslySetInnerHTML={{ __html: currentArticle.content }} />
          <div style={{ textAlign: 'center' }}>
            <img src={NewsImage} />
          </div>
        </Article>
      </div>

      <Headline style={{ marginTop: '3em' }}><FormattedMessage id="news.otherArticles" /></Headline>
      
      <div className="testcontainer">
        <div className="otherArticle-container row">
          {
            articles[lang].filter(a => a.path !== path).map(a =>
              <div className="col-md-6 article-column">
                <OtherArticle title={a.title} path={a.path} date={a.date} author={a.author} />
                {/* <div style={{ border: '2px solid black', width: '100%', height: '100px'}}></div> */}
                
              </div>
            )
          }
        </div>
      </div>

      <Footer />

    </div>
  )
}

export default App;


const OtherArticleHeadline = styled.div`
  background-color: transparent;
  color: var(--midnight-blue);
  font-weight: 400;
  height: auto;
  line-height: 32px;
  text-align: left;
  width: 400px;

  font-family: 'PT Sans', Helvetica, Arial, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.00px;

  margin-left: 22px;
  margin-top: 0.5em;
`

const OtherArticleAuthor = styled.div`
  background-color: transparent;
  color: var(--midnight-blue);
  font-weight: 700;
  height: auto;
  line-height: 23px;
  text-align: left;
  margin-left: 22px;
  margin-top: 1.5em;

  font-family: 'PT Sans', Helvetica, Arial, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.00px;
`

const OtherArticleDate = styled.div`
  background-color: transparent;
  color: var(--midnight-blue);
  font-weight: 400;
  height: auto;
  line-height: 24px;
  text-align: left;
  width: 394px;
  margin-left: 22px;

  font-family: 'PT Sans', Helvetica, Arial, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.00px;
`

function OtherArticle({ title, path, date, author }) {
  const history = useHistoryLangQueryParam()

  return (
    <div style={{ marginBottom: '3em', cursor: "pointer" }} onClick={() => history.push(`/news/${path}`)}>
      <img src={OtherArticleImage} style={{ width: '90%'}} />
      {/* <OtherArticleHeadline>{title}</OtherArticleHeadline> */}
      <div className="article-header">{title}</div>
      {/* <OtherArticleAuthor>{author}</OtherArticleAuthor> */}
      <div className="article-author">{author}</div>
      {/* <OtherArticleDate><FormattedMessage id="news.publishedOn" /> {date}</OtherArticleDate> */}
      <div className="article-date">{date}</div>
    </div>
  )
}


