const PurchaseService = {
    createSig: async (shoppingCart) => {

        const purchaseItems = shoppingCart.products.map(p => ({
            product: p,
            count: 1
        }))
        var purchaseSig = { items: purchaseItems }

        return await fetch('/api/purchase/purchase/create-sig', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(purchaseSig)
        })
    },
    finilizePurchase: async (paymentData, purchaseSig, paymentConfirmation, lang) => {
        return await fetch(`/api/purchase/purchase`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                lang,
                invoiceAddress: {
                    email: paymentData.email,
                    firstName: paymentData.firstName,
                    lastName: paymentData.lastName,
                    companyName: paymentData.companyName,
                    street: paymentData.street,
                    number: paymentData.houseNumber,
                    zipCode: paymentData.zipCode,
                    city: paymentData.city,
                },
                deliveryAddress: {
                    email: paymentData.email,
                    firstName: paymentData.firstName,
                    lastName: paymentData.lastName,
                    companyName: paymentData.companyName,
                    street: paymentData.street,
                    number: paymentData.houseNumber,
                    zipCode: paymentData.zipCode,
                    city: paymentData.city,
                },
                signed: purchaseSig,
                paymentConfirmation,
                taxId:paymentData.taxId,
            })
        })
    } 
}

export default PurchaseService