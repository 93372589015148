import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Column, Row, Filler } from '../../components/base';
import Footer from '../../components/footer';
import InlineSearchBar from '../../components/inlinesearchbar';
import Button from '../../components/button';

import "./style.scss";
import "./style2.scss";

import BackgroundImage from '../../assets/mena-header-immoscout.png';
import Jumbotron from '../../components/jumbotron';

import Img1 from '../../assets/immoscout-img-1.jpg';
import Img2 from '../../assets/immoscout-img-2.jpg';
import Icon from '../../assets/Partner.png';
import FormattedHTMLMessage from '../../components/formattedhtmlmessage';


function App() {  

  return (
    <div className="immo-container">
      <Jumbotron 
        title={<FormattedHTMLMessage id="immoscout.title" />} 
        img={BackgroundImage} textStyle={{color: 'white', fontWeight: 900, top: '300px' }} 
        icon={Icon}/>

      <div className="row immo-row">
        <div className="col-md-8">
          {/* <HeaderSmall><FormattedMessage id="immoscout.block1.smallHeader" /></HeaderSmall> */}
          <div className="title"><FormattedMessage id="immoscout.block1.smallHeader" /></div>
          {/* <Header><FormattedMessage id="immoscout.block1.header" /></Header> */}
           <div className="subtitle"><FormattedMessage id="immoscout.block1.header" /></div>
          {/* <Text><FormattedMessage id="immoscout.block1.content" /></Text> */}
          <div className="content"><FormattedMessage id="immoscout.block1.content" /></div>
        </div>
        <div className="col-md-4">
          <img src={Img1} />
        </div>
      </div>

      <InlineSearchBar />

      <div className="row immo-row">
        <div className="col-md-6 umwelt-image">
          <img src={Img2} />
        </div>
        <div className="col-md-6 umwelt-text-container">
          <div className="title"><FormattedMessage id="immoscout.block2.smallHeader" /></div>
          <div className="subtitle"><FormattedMessage id="immoscout.block2.header" /></div>
          <div className="content"><FormattedMessage id="immoscout.block2.content" /></div>
          {false && <Button label={<FormattedMessage id="immoscout.block2.download" />} style={{ marginTop: '2em' }} />}
        </div>
      </div>

      <Footer />
      
    </div>
  );
}

export default App;