import store from "../redux/store";
import ReactPixel from 'react-facebook-pixel';
import { get } from "lodash";
import { useMatomo} from '@datapunt/matomo-tracker-react';

export const initCookieUsage = () => {

    const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
    };

    ReactPixel.init('303422871677103', {}, options);
    ReactPixel.pageView();
    ReactPixel.revokeConsent();
}

store.subscribe(() => {
    const state = store.getState();
    if(!state.cookieConsent.saved)
    {
        return
    }

    console.log("Updating Cookie Usage");
    console.log(state);
    
    if(state.cookieConsent.marketing) {

        ReactPixel.grantConsent();
    } else {
        ReactPixel.revokeConsent();
    }

    if (state.cookieConsent.statistics) {

        if (window.location.host == "www.noisemap.eu") {
            console.log("Matomo tracking starting");
            
            var _paq = window._paq = window._paq || [];
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
        }
    }
})


