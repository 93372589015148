import styled from 'styled-components';
import { Row } from './base';
import InputField from './inputfield';
import InputFieldIban from './inputfield-iban';
import InputFieldCreditCardNumber from './inputfield-creditcard-number';
import InputFieldCreditCardValidUntil from './inputfield-creditcard-validuntil';
import InputFieldCreditCardCVV from './inputfield-creditcard-cvv';
import Dropdown from './dropdown';
import InputArea from './inputarea';

export const Label = styled.div`
  color: rgba(0, 53, 95, 1);
  font-family: "PT Sans", Helvetica;
  font-size: 18px;
  font-style: normal;
  padding: 0.7em;
`

export const FormRow = styled(Row)`
  margin-bottom: 0.5em;
`

export const FormLabel = styled(Label)`
  flex: 1;
  text-align: right;
`

export const FormInputField = styled(InputField)`
  flex: 1;
`

export const FormInputFieldIban = styled(InputFieldIban)`
  flex: 1;
`

export const FormInputFieldCreditCardNumber = styled(InputFieldCreditCardNumber)`
  flex: 1;
`

export const FormInputFieldCreditCardValidUntil = styled(InputFieldCreditCardValidUntil)`
  flex: 1;
`

export const FromInputFieldCreditCardCVV = styled(InputFieldCreditCardCVV)`
  flex: 1;
`

export const FormDropdown = styled(Dropdown)`
  flex: 1;
`

export const FormInputArea = styled(InputArea)`
  flex: 1;
`

export const MultiFieldWrapper = styled.div`
  flex: 1;
  display: flex;

  > div {
    margin-right: 0.5em;
  }

  > div:last-child {
    margin-right: 0;
  }
`