
export const SET_COOKIE_CONSENT = "SET_COOKIE_CONSENT"

export default function cookieContentReducer(state = {}, action) {
  switch (action.type) {
    case SET_COOKIE_CONSENT:
      const newState = action.cookieConsent
      console.log("New state", newState)
      return newState
    default:
      return state
  }
}