import { values } from 'lodash'
import { useCallback } from 'react'
import InputField from './inputfield'

export default function InputFieldIban({ value, onChange, ...rest }) {
  console.log("value before", value)

  const onChangeWrapper = useCallback(evt => {
    let value = evt.target.value.replace(/[^0-9A-Za-z]/g, '')
    value = value.substr(0, 22)
    value = value.toUpperCase()
    const newEvent = {target: {value}}
    console.log("New Event", newEvent)
    onChange(newEvent)
  }, [onChange])

  value = value || ''
  value = value.replace(/[^0-9A-Z]/g, '')
  value = value.substr(0, 22)

  const part0 = value.substr(0, 4)
  const part1 = value.substr(4, 4)
  const part2 = value.substr(8, 4)
  const part3 = value.substr(12, 4)
  const part4 = value.substr(16, 4)
  const part5 = value.substr(20, 4)

  value = part0 + (part1.length ? ` ${part1}` : '') + (part2.length ? ` ${part2}` : '')
  + (part3.length ? ` ${part3}` : '')  + (part4.length ? ` ${part4}` : '')
  + (part5.length ? ` ${part5}` : '')

  console.log("value after", value)

  return (
    <InputField value={value} onChange={onChangeWrapper} {...rest} />
  )
}