import React from 'react'
import styled from 'styled-components'

import Animation from '../assets/loading.svg'

const LoadingStyle = styled.img`
  @keyframes rotate{
    to{ transform: rotate(360deg); }
  }

  animation: rotate 1.5s linear infinite; 

  display: inline-block;
  width: 1em;
  height: 1em;

  position: relative;
  top: 0.2em;
`

export default function Loading() {
  return <LoadingStyle src={Animation} />
}