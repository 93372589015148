import { Helmet } from "react-helmet"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"
import { useLang } from "./langutils"

import SchemaOrg from '../assets/schema-org.json'

const languages = ['de', 'en']

const calcHrefLang = (curLang, lang, base, pathname, search) => {
  pathname = pathname.replace(`/${curLang}/`, `/${lang}/`)
  if(pathname === '/' && lang !== 'de') {
    pathname = `/${lang}/`
  }
  if(pathname === '/de/') {
    pathname = '/'
  }
  return `${base}${pathname}${search}`
}

export default function PageWrapper({cssClassName, children}) {
  const history = useHistory()
  const intl = useIntl()
  const lang = useLang()
  
  let prefix = '/'
  if(history.location.pathname.match(/^\/[a-z]{2}\//)) {
    prefix = history.location.pathname.substr(0, 4)
  }

  let identifier = history.location.pathname.substr(prefix.length)

  let slashIdx = identifier.indexOf("/")
  if(slashIdx > -1) {
    identifier = identifier.substr(0, slashIdx)
  }
  if(identifier === '') {
    identifier = 'start'
  }

  const base = document.location.href.substr(0, document.location.href.indexOf('/', 9))  
  
  cssClassName = cssClassName || `${identifier}-page`

  return (
    <>
      <Helmet>
        <title>{ intl.formatMessage({id: `${identifier}.html.title`}) + intl.formatMessage({id: `html.title.suffix`}) }</title>
        <meta name="description" content={ intl.formatMessage({id: `${identifier}.html.description`}) } />
        <link rel="canonical" href={ `${base}${history.location.pathname}` } />
        <meta name="robots" content="index, follow" />
        {
          languages.map(l => <link rel="alternate" hreflang={l.toUpperCase()} href={calcHrefLang(lang, l, base, history.location.pathname, history.location.search)} />)
        }
        <script type="application/ld+json">
          { JSON.stringify(SchemaOrg) }
        </script>
      </Helmet>
      <div className={cssClassName}>
        {children}
      </div>
    </>
  )
}