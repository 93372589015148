import React, {useCallback, useRef, useState} from 'react'
import styled from 'styled-components'

export const NotificationContext = React.createContext(null);

const NotificationBox = styled.div`
  background-color: rgb(0, 53, 95);
  position: fixed;
  right: -23em;
  top: 1em;
  padding: 1em;
  color: white;
  font-family: "PT Sans", Helvetica;
  width: 20em;
  line-height: 1.7em;
  z-index: 9999;
  border: 2px solid white;

  transition: right 0.5s;

  .title {
    font-weight: 700;
    margin-bottom: 0.5em;
  }

  &.active {
    right: 1em;
  }
`

function Notification({title, message, active}) {
  return (
    <NotificationBox className={active ? 'active' : null}>
      <div className="title">{title}</div>
      <div className="message">{message}</div>
    </NotificationBox>
  )
}

export default function NotificationWrapper({children}) {
  const [texts, setTexts] = useState({title: null, message: null});
  const [active, setActive] = useState(false);

  const timeoutRef = useRef(null);

  const showNotification = useCallback((title, message) => {
    //console.log("notification", title, message);
    clearTimeout(timeoutRef.current);
    setTexts({ title, message });
    setActive(true);

    timeoutRef.current = setTimeout(() => setActive(false), 4000);
  });

  return (
    <NotificationContext.Provider value={{showNotification}}>
      <Notification {...texts} active={active} />
      {children}
    </NotificationContext.Provider>
  )
}