import React from 'react'
import styled from 'styled-components'
import './jumbotron.scss';

const Headline = styled.h1`
  font-family: "Montserrat", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;

  color: var(--midnight-blue);
  left: 418px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: 275px;
  width: 603px;
`

const Background = styled.img`
  position: absolute;
  top: 110px;
  width: 1440px;
  height: 440px;
`

const OverlapGroup = styled.div`
  height: 550px;
  position: relative;
  width: 1440px;
  margin-bottom: 2em;
`

export default function Jumbotron({title, img, textStyle = {}, icon=null }) {
  /*return (
    <OverlapGroup>
      <Background
        src={img}
      />
      <Headline style={textStyle}>{title}</Headline>
      <div className="background-1"></div>
    </OverlapGroup>
  )*/

  return (
    <div className="jumbotron-container">
      <div className="image-container" style={{backgroundImage: `url(${img})`}}>
        <div className="text-container">
          {icon &&<img className="icon" src={icon} />}
          <h1 style={{...textStyle}}> {title}</h1>
        </div>
      </div>
    </div>
  )
}