
export const SET_PAYMENT_DATA = "SET_PAYMENT_DATA"

export default function paymentDataReducer(state = {}, action) {
  switch (action.type) {
    case SET_PAYMENT_DATA:
      const newState = action.paymentData
      console.log("New state", newState, action)
      return newState
    default:
      return state
  }
}