import { useHistory } from "react-router-dom";
import App from ".";
import { useHistoryLangQueryParam } from "../../components/langutils";
import PageWrapper from "../../components/page-wrapper";

export default function() {
  const history = useHistory()
  if(history.location.pathname !== '/' && !history.location.pathname.match(/^\/[a-z]{2}\/?$/)) {
    history.replace('/')
  }

  return (
    <PageWrapper cssClassName="start-page">
      <App />
    </PageWrapper>
  )
}