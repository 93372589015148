import React, { useEffect, useState } from 'react';
import { Column } from '../../../components/base';
import Footer from '../../../components/footer';
import Jumbotron from '../../../components/jumbotron';
import FormattedHTMLMessage from '../../../components/formattedhtmlmessage';
import styled from 'styled-components';
import { useLang } from '../../../components/langutils';

import "./style.scss";
import "./style2.scss";
import SectionHead from '../../../components/sectionhead';

import BackgroundImg from '../../../assets/checkout.png'
import ThankYouImage from '../../../assets/thank-you.jpg';
import SearchBar from '../../../components/searchbar';
import ThankYouImageDe from '../../../assets/thank-you-text.png';
import ThankYouImageEn from '../../../assets/thank-you-text-en.png';

const Label = styled.div`
  color: rgba(0, 53, 95, 1);
  font-family: "PT Sans", Helvetica;
  font-size: 18px;
  font-style: normal;
`


function App() {
  /*return (
    <div className="suchergebnis">
      <Jumbotron title={<FormattedHTMLMessage id="checkout.thankYou.title" />} img={BackgroundImg} />
      <div style={{ minWidth: "80%", marginTop: "50px" }}>
        <Column style={{width: '100%'}}>
          <div style={{ position: "relative" }}>
            <img style={{ width: '75%', marginLeft: "auto", marginRight: "auto" }} src={ThankYouImage} />
            <div style={{ position: "absolute", top: "180px", left: "320px" }}>
              <SectionHead><FormattedHTMLMessage id="checkout.thankYou.head" /></SectionHead>
              <Label><FormattedHTMLMessage id="checkout.thankYou.content" /></Label>
            </div>
          </div>
          
        </Column>

        <SectionHead></SectionHead>

        <SearchBar />
      </div>
      <div className="overlap-group1">
        <Footer />
      </div>
    </div>
  );*/
  
  const [thankYouImage, setThankYouImage] = useState(ThankYouImage);

  const lang = useLang();

  useEffect(() => {
    
    lang === 'de' ? setThankYouImage(ThankYouImageDe) : setThankYouImage(ThankYouImageEn);
    
  }, [lang])

  return (
    <div className="thank-you-container">
      <Jumbotron title={<FormattedHTMLMessage id="checkout.thankYou.title" />} img={BackgroundImg} />

      <div className="content-container">
        <div className="img-container">
          <img className="thank-you-img" src={thankYouImage} />
        </div>

        <SectionHead></SectionHead>

        <SearchBar />
      </div>

      <Footer />
      
    </div>
  );
}

export default App;
