import { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import SearchResult from '../assets/search-result.jpg'
import SearchResultBasic from '../assets/search-result-basic.jpg'
import FormatCurrency from '../util/format-currency';
import { Column, Filler, Row } from './base';
import Button from './button';
import { NotificationContext } from './notification';
import { ADD_PRODUCT } from '../redux/shopping-cart-reducer'
import { useHistoryLangQueryParam } from './langutils';
import FormattedHTMLMessage from './formattedhtmlmessage';

import featureIcon from '../assets/plusMena100.png'

import "./card-search-result.scss";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  max-width: 35em;
`

const Image = styled.img`
  width: 35em;
  height: 11em;
  object-fit: none;
`

const ProductNameShort = styled.div`
  color: white;
  font-family: "PT Sans", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;

  left: 37px;
  line-height: 65px;
  position: absolute;
  top: 104px;
  white-space: nowrap;
  width: 168px;
`

const Infos = styled(Column)`
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 1.5em;
`

const ProductName = styled.div`
  color: rgba(0, 53, 95, 1);  
  font-family: "PT Sans", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 1em;
`

const ProductDescription = styled.div`
  color: rgba(0, 53, 95, 1);  
  font-family: "PT Sans", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 1em;
  height: 5.5em;

  strong {
    font-weight: 700;
  }
`

const ProductAddress = styled.div`
  color: rgba(0, 53, 95, 1);  
  font-family: "PT Sans", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 1em;
`

const AvailableSince = styled.div`
  color: rgba(0, 53, 95, 1);
  font-family: "PT Sans", Helvetica;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
`

const Price = styled.div`
  color: rgba(0, 53, 95, 1);
  font-family: "PT Sans", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 2em;
`

export default function CardSearchResult({searchResult}) {
  const history = useHistoryLangQueryParam()
  const dispatch = useDispatch()
  const { showNotification } = useContext(NotificationContext)
  const shoppingCart = useSelector(({ shoppingCart }) => shoppingCart)
  const intl = useIntl()

  const lang = intl.formatMessage({id: "lang" })

  const addProductToShoppingCart = useCallback((product) => {
    const productName = `${product.productName[lang]} ${product.productMeta.address}`
    if(shoppingCart.products.find(p => p.sku === product.sku && p.productMeta.address === product.productMeta.address)) {
      showNotification(intl.formatMessage({id: "shoppingCart.productNotAddedToCart.title" }), intl.formatMessage({id: "shoppingCart.productNotAddedToCart.text" }).replaceAll("[productName]", productName))  
      return
    }
    dispatch({ type: ADD_PRODUCT, product })
    showNotification(intl.formatMessage({id: "shoppingCart.productAddedToCart.title" }), intl.formatMessage({id: "shoppingCart.productAddedToCart.text" }).replaceAll("[productName]", productName))  
  }, [dispatch, showNotification, shoppingCart, intl])

  return (
    <div className="result-container col-md-6">
      <div className="inner-container">
        <div className="resultcard-header">
          <div className="resultcard-title"><h3>{searchResult.productName[lang]}</h3></div>
          <div className="row resultcard-address">
            <div className="col-md-3">
              <p><b>{<FormattedMessage id="noiseFactSheet.address" />}:</b></p>
            </div>
            <div className="col-md-9">
              {searchResult.productMeta.address}
            </div>
          </div>
        </div>

        {(searchResult.sku === 'NFS-B') &&
          <div className="img-container">
            <img src={SearchResultBasic} alt="" />
            <div>{searchResult.productNameShort[lang]}</div>
          </div>
        }

        {(searchResult.sku === 'NFS-D') &&
          <div className="img-container">
            <img src={SearchResult} alt="" />
            <div>{searchResult.productNameShort[lang]}</div>
          </div>
        }

        {/* <div className="resultcard-date"><FormattedMessage id="searchResults.availableSince" /> 01.01.2021</div> */}
        


        <div className="resultcard-description" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `searchResults.productDescription.${searchResult.sku}` }) }} ></div>

        {(searchResult.sku === 'NFS-B') &&
        
          <div className='resultcard-features'>
            <table>

            <tr>
              <ResultDescriptionItem description={<FormattedMessage id="noiseFactSheet.features.1" />}></ResultDescriptionItem>
            </tr>
            
            <tr>
              <ResultDescriptionItem description={<FormattedMessage id="noiseFactSheet.features.2" />}></ResultDescriptionItem>
            </tr>
            
            </table>
          </div>
        }

        {
          (searchResult.sku === 'NFS-D') &&
          <div className='resultcard-features'>
            <ResultDescriptionItem description={<FormattedMessage id="noiseFactSheet.features.1" />}></ResultDescriptionItem>
            <ResultDescriptionItem description={<FormattedMessage id="noiseFactSheet.features.2" />}></ResultDescriptionItem>
            <ResultDescriptionItem description={<FormattedMessage id="noiseFactSheet.features.3" />}></ResultDescriptionItem>
            <ResultDescriptionItem description={<FormattedMessage id="noiseFactSheet.features.4" />}></ResultDescriptionItem>
          </div>
        }


        

        <Price><FormattedMessage id="searchResults.price" /> <FormatCurrency value={searchResult.price} /> €</Price>

        
        <div className="row">
          <div className="card-button col-md-6">
            <Button label={<FormattedMessage id="searchResults.directBuy" />} style={{ marginRight: "1em" }} onClick={() => { addProductToShoppingCart(searchResult); history.push("/payment") }} />
          </div>
          <div className="card-button col-md-6">
            <Button label={<FormattedMessage id="searchResults.addToCart" />} onClick={() => addProductToShoppingCart(searchResult)} />
          </div>
          
        </div>
        
      </div>
      
        
      
    </div>
  );
}

function ResultDescriptionItem(props) {
  const {description} = props;

  return (
    <div className="result-description-item">
      <td className='img-container'><img className="result-description-item-icon" src={featureIcon} alt="" /></td>
      <td><div className="result-description-item-description">{description}</div></td>
        
    </div>
  )
}