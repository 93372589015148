
export const ADD_PRODUCT = "ADD_PRODUCT"
export const REMOVE_PRODUCT = "REMOVE_PRODUCT"
export const CLEAR_SHOPPING_CART = "CLEAR_SHOPPING_CART"

export default function shoppingCartReducer(state = {products: [], sum: 0}, action) {
  switch (action.type) {
    case ADD_PRODUCT: {
      const newProducts = [...state.products, action.product]
      const sum = newProducts.reduce((p, c) => p + c.price, 0)
      return {...state, products: newProducts, sum}
    }
    case REMOVE_PRODUCT: {
      const product = action.product
      const newProducts = state.products.filter(p => JSON.stringify(product) !== JSON.stringify(p))
      const sum = newProducts.reduce((p, c) => p + c.price, 0)
      return {...state, products: newProducts, sum}
    }
    case CLEAR_SHOPPING_CART: {
      const newProducts = []
      const sum = 0
      return {...state, products: newProducts, sum}
    }
    default:
      return state
  }
}