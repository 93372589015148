import React from 'react'
import { useIntl } from 'react-intl';
import styled from 'styled-components'
import SearchResult from '../assets/search-result.jpg'
import FormatCurrency from '../util/format-currency';
import { Filler } from './base'
import Button from './button'
import FormattedHTMLMessage from './formattedhtmlmessage';
import './productrow.scss';

/*const Image = styled.img`
  height: 4em;
`*/

const ProductRow = ({deleting, product, onDelete, className, ...rest }) => {
  const intl = useIntl()
  const lang = intl.formatMessage({id: "lang" })

  /*return (
    <div className={`${className} ${deleting ? "deleting" : null}`} {...rest}>
      <Image src={SearchResult} />
      <div>
        <div className="productName">{product.productName[lang]}</div>
        <div className="address">{product.productMeta.address}</div>
      </div>
      <Filler />
      <div className="price"><FormatCurrency value={product.price} /> €</div>
      { onDelete &&
        <Button label={<FormattedHTMLMessage id="shoppingCart.remove" />} onClick={() => onDelete(product) } />
      }
    </div>
  )*/
  return (
    <div className={`product-container ${className} ${deleting ? "deleting" : null}`} {...rest}>
      <div className="row">
        <div className="img-container col-md-2"><img src={SearchResult} /></div>
        <div className="col-md-8">
          <div className="row product-text-container">
            <div className="col-md-9">
              <div className="productName">{product.productName[lang]}</div>
              <div className="address">{product.productMeta.address}</div>
            </div>
            <div className="col-md-3">
              <div className="price"><FormatCurrency value={product.price} /> €</div>
            </div>
          </div>
        </div>
        <div className="col-md-2 delete-button">
            
          { onDelete &&
            <Button label={<FormattedHTMLMessage id="shoppingCart.remove" />} onClick={() => onDelete(product) } />
          }
        </div>
      </div>
      
    </div>
  );
}

/*const ProductRowStyled = styled(ProductRow)`
  font-family: "PT Sans", Helvetica;
  color: rgba(0, 53, 95, 1);
  display: flex;
  border: 1px solid rgb(0, 53, 95);
  border-radius: 0.5em;
  overflow: hidden;
  align-items: center;
  margin-bottom: 0.5em;

  transition: opacity 1s;

  &.deleting {
    opacity: 0;
  }

  * {
    margin-right: 1em;
  }

  .productName {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0.3em;
  }

  .price {
    font-weight: 700;
  }
`*/

//export default ProductRowStyled
export default ProductRow