import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { LinkLangQueryParam } from './langutils'
import FormattedHTMLMessage from './formattedhtmlmessage'

import { Column } from './base'

import Logo from '../assets/logo.png'

import './footer.scss';

import IconFacebook from '../assets/icon-facebook.jpg'
import IconTwigger from '../assets/icon-twitter.jpg'
import IconInstagram from '../assets/icon-instagram.jpg'

const FooterWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  height: 577px;
  left: 28px;
  min-width: 1475px;
  margin-top: 3em;
`

const FooterBackground = styled.div`
  background-color: rgb(0, 53, 95);
  align-items: flex-start;
  display: flex;
  left: 8px;
  min-width: 1459px;
  padding-top: 3em;
  padding-bottom: 3em;
  padding-left: 119px;
  padding-right: 119px;
`

const CopyrightText = styled.div`
  margin-top: 2em;

  color: rgba(38,43,54,1.0);
  font-weight: 400;
  height: auto;
  line-height: 28px;
  text-align: center;
  white-space: nowrap;

  font-family: 'PT Sans', Helvetica, Arial, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.00px;
`

const ColumnHeader = styled.div`
  color: white;
  font-family: "PT Sans", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
`

const ColumnList = styled.ul`
  font-family: "PT Sans", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  color: white;
  line-height: 48px;
  margin-top: 13px;
  min-height: 192px;
`

const StyledLink = styled(LinkLangQueryParam)`
  color: white;
  text-decoration: none;
`

const StyledA = styled.a`
  color: white;
  text-decoration: none;
`

const SocialMediaIcon = styled.img`
  width: 1em;
  margin-right: 0.25em;
`

export default function Footer() {

  return (
    <div className="footer-wrapper">
      <div className="footer-container">
        <div className="row">
          <div className="col-md-4 contact-container">
            <div className="mena-gmb-h-the-measur heroparagraph">
                  <img
                    src={Logo}
                    style={{width: '10em', marginTop: '-20px'}}
                  /><br/><br/>
                  <div className="contact-long"><FormattedHTMLMessage id="footer.menaContact" /></div>
                  <div className="contact-short"><FormattedHTMLMessage id="footer.menaContactShort" /></div>
              </div>
            </div>
          <div className="col-md-4 link-container">
            <ColumnHeader><FormattedMessage id="footer.aboutUs" /></ColumnHeader>
            <ColumnList>
              <li><StyledLink to="/contact"><FormattedMessage id="footer.contact" /></StyledLink></li>
              <li><StyledLink to="/privacy"><FormattedMessage id="footer.privacy" /></StyledLink></li>
              <li><StyledLink to="/business-terms"><FormattedMessage id="footer.businessTerms" /></StyledLink></li>
              <li><StyledLink to="/imprint"><FormattedMessage id="footer.imprint" /></StyledLink></li>
              <li><StyledLink to="/faq"><FormattedMessage id="footer.faqs" /></StyledLink></li>
            </ColumnList>
          </div>
          <div className="col-md-4 social-container">
            <ColumnHeader><FormattedMessage id="footer.stayConnected" /></ColumnHeader>

            <ColumnList>
              <li><StyledA target="_blank" href="https://www.facebook.com/menaGmbH">
                <SocialMediaIcon
                  src={IconFacebook}
                /> Facebook</StyledA></li>
              {false &&
                <li><img
                  style={{ display: "none" }}
                  src="https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/startseite-path-8B7356B0-437A-4BAF-8ED6-60EC36C6C796@2x.png"
                /> LinkedIn</li>
              }
              <li>
                <StyledA target="_blank" href="https://www.instagram.com/mena_noisemap/">
                  <SocialMediaIcon
                    src={IconInstagram}
                  /> Instagram
                </StyledA></li>
            </ColumnList>
          </div>
        </div>
      </div>

      <div className="copyright-container">
        Copyright © {new Date().getFullYear()} . Mena GmbH
      </div>
    </div>
  );
}