import React, {useState} from 'react';
import { FormattedMessage } from 'react-intl';
import { Column } from '../../../components/base';
import Footer from '../../../components/footer';
import FormattedHTMLMessage from '../../../components/formattedhtmlmessage';
import styled from 'styled-components';
import { Base64 } from 'js-base64';

import "./style.scss";
import "./style2.scss";
import Button from '../../../components/button';
import SectionHead from '../../../components/sectionhead';
import Jumbotron from '../../../components/jumbotron';
import { useParams } from 'react-router-dom';
import Loading from '../../../components/loading';

import BackgroundImg from '../../../assets/checkout.png'

const Text = styled.div`
  font-family: "PT Sans", Helvetica;
  color: rgba(0, 53, 95, 1);
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0.3em;
`

function App() {
  
  const [isDownloading, setIsDownloading] = useState(false);
  
  const { key } = useParams();
  
  const objJson = Base64.decode(key);
  const obj = JSON.parse(objJson);
  

  console.log(obj);


  const onDownload = () => {
    console.log("clicked");
    setIsDownloading(true);
    window.location.href = `/api/mena/noise/products/download?key=${key.replace(/\+/g, '_')}`;
  }
  
  return (
    <div className="download-container">
      <Jumbotron title={<FormattedHTMLMessage id="checkout.download.title" />} img={BackgroundImg} />
      <div className="content-container">
        <div style={{ minWidth: "80%" }}>
          <Column style={{ width: '100%' }}>
            <SectionHead><FormattedMessage id="checkout.clickHereForDownload" /></SectionHead>

            <Text>{obj.a}</Text>
            
            <Button label={<FormattedMessage id="checkout.download" />} onClick={onDownload} />
            
            { isDownloading &&
              <>
              <Loading /> <span style={{ color: "var(--midnight-blue)", fontFamily: "PT Sans, Helvetica" }}>&nbsp;<FormattedMessage id="checkout.download" />...</span>
              </>
            }

            </Column>
        </div>
      </div>
      
      
      <Footer />
      
    </div>
  );
}

export default App;