
import { get, isObject } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Column, Row } from './base';
import Button from "./button";
import DropdownHierarchical from "./dropdown-hierarchical";
import InputField from "./inputfield";
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistoryLangQueryParam } from './langutils'
import './searchbar.scss'
import queryString from 'query-string';

const SearchBarBackground = styled.div`
  /*height: 235px;*/
  margin-bottom: -23px;
  position: relative;
  display: flex;
  flex-direction: column;
`

/*const cities = [
  "amsterdam",
  "berlin", 
  "bordeaux",
  "clermont-ferrand",
  "cologne",
  "dijon",
  "frankfurt-am-main",
  "grenoble",
  "hamburg",
  "lyon",
  "marseille",
  "munich", 
  "nantes",
  "nice",
  "nuernberg",
  "paris",
  "potsdam",
  "saint-etienne",
  "stuttgart",
  "vienna",
  "zurich"
  ]*/

  const citiesByCountry = [
    {
      country:'ger',
      cities:
      [
        "berlin",
        "frankfurt-am-main",
        "hamburg",
        "munich",
        "nuernberg",
        "potsdam",
        "stuttgart",
      ]
    },
    {
      country:'fr',
      cities:
      [
        "bordeaux",
        "clermont-ferrand",
        "dijon",
        "grenoble",
        "lyon",
        "marseille",
        "nantes",
        "nice",
        /*"paris",*/
        "saint-etienne",
      ]
    },
    {
      country:'nl',
      cities:
      [
        "amsterdam",
      ]
    },
    {
      country:'at',
      cities:
      [
        "vienna",
      ]
    },
    /*{
      country:'ch',
      cities:
      [
        "zurich",
      ]
    }*/
  ]




export default function SearchBar({ theme, showTitleBox }) {
  const [selectedCity, setSelectedCity] = useState(null)
  const [address, setAddress] = useState("")
  const [errors, setErrors] = useState([])
  const intl = useIntl()
  const history = useHistoryLangQueryParam()
  const location = useLocation()

  //const translatedCities = useMemo(() => cities.map(c => ({ label: intl.formatMessage({id: `cities.${c}`}), value: c })), [intl])
  const sortedCities = useMemo(() => citiesByCountry.map(
    countryObject => ({"country" : countryObject.country, "cities": countryObject.cities.sort()})
    ), [intl]);
  
  const translatedCities = useMemo(() => sortedCities.map(
      countryObject => ({ "country" : countryObject.country, "cities": countryObject.cities.map(
          c => ({label: intl.formatMessage({id: `cities.${c}`}), value: c})
      )})
    ) ,[intl])

  const validate = useCallback(() => {
    const errors = []
    if(!get(address, "length")) {
      errors.push("address_is_null")
    }
    if(!selectedCity) {
      errors.push("city_is_null")
    }
    setErrors(errors)

    return !errors.length
  }, [address, selectedCity, setErrors])

  const resetError = useCallback(errorId => {
    const newErrors = errors.filter(e => e !== errorId)
    setErrors(newErrors)
  }, [errors, setErrors])

  const submit = useCallback(() => {
    if(!validate()) {
      return;
    }
    history.push(`/search?c=${selectedCity}&a=${encodeURIComponent(address)}`)
    console.log(encodeURIComponent(address));
  }, [validate, history, selectedCity, address])

  useEffect(() => {

    const cityParam = queryString.parse(window.location.search);

    if(cityParam.city)
    {
      console.log(`searched city: ${cityParam.city}`);
      setSelectedCity(cityParam.city);
    }
  }, [location])


  return <SearchBarBackground>
    <div className="row city-row" >
      {showTitleBox &&
      <div className="search-title-box">
        
        <h3>{intl.formatMessage({id: "searchbar.new.searchAddress"})}</h3>
        <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({id: "searchbar.new.callToAction"}) }}></p>
      </div>
      }
      
      <div className="col-md-4 city-dropdown" ><DropdownHierarchical theme={theme} items={translatedCities} placeholder={<FormattedMessage id="searchbar.cities" />} value={selectedCity} onChange={e => resetError("city_is_null") || setSelectedCity(e)} errors={errors.filter(e => e === "city_is_null").map(e => <FormattedMessage id="searchbar.pleaseSelectCity" />)} /></div>
      <div className="col-md-4 city-input">
        <InputField 
          theme={theme} 
          placeholder={intl.formatMessage({id: "searchbar.streetAddress"})} 
          alignment="center"
          value={address} 
          onChange={e => resetError("address_is_null") || setAddress(e.target.value)} 
          errors={errors.filter(e => e === "address_is_null").map(e => <FormattedMessage id="searchbar.pleaseEnterAddress" />)} 
          onEnterPress={submit} />
      </div>
      <div className="col-md-4 city-button"><Button label={<FormattedMessage id="searchbar.search"/>} onClick={submit} /></div>
    </div>
  </SearchBarBackground>
}