import { values } from 'lodash'
import { useCallback } from 'react'
import InputField from './inputfield'

export default function InputFieldCreditCardCVV({ value, onChange, ...rest }) {
  const onChangeWrapper = useCallback(evt => {
    let value = evt.target.value.replace(/[^0-9]/g, '')
    value = value.substr(0, 3)
    const newEvent = {target: {value}}
    onChange(newEvent)
  }, [onChange])

  value = value || ''
  value = value.replace(/[^0-9]/g, '')
  value = value.substr(0, 3)

  return (
    <InputField value={value} onChange={onChangeWrapper} {...rest} />
  )
}