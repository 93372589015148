import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl'
import CardSearchResult from '../../components/card-search-result';
import Footer from '../../components/footer';
import Jumbotron from '../../components/jumbotron';
import SearchBar from '../../components/searchbar';
import FormattedHTMLMessage from '../../components/formattedhtmlmessage';

import "./style.scss";
import "./style2.scss";

import BackgroundImg from '../../assets/checkout.png'
import Loading from '../../components/loading';


const ResultText = styled.div`
  font-family: "PT Sans",Helvetica;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  color: var(--midnight-blue);
  line-height: 55px;
  margin-top: 30px;
  min-height: 100px;
  min-width: 510px;
  text-align: center;
`

function App({searchResults}) {

  const intl = useIntl()
  /*return (
    <div className="suchergebnis">
      
      <Jumbotron title={<FormattedHTMLMessage id="searchResults.title" />} img={BackgroundImg} />

      { !searchResults && 
      <ResultText>
        <Loading /> <FormattedMessage id="searchResults.searching" />
      </ResultText>
      }

      { searchResults && searchResults.length === 0 &&
      <ResultText>
        <FormattedMessage id="searchResults.noResults" />
      </ResultText>
      }

      { searchResults && 

      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
        { searchResults.map(r =>
          <CardSearchResult 
            searchResult={r}
          />
        )}
      </div>
        }

      <SearchBar />

      { false && 
      <img
        className="teaser-keine-treffer"
        src="https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/suchergebnis-teaserkeinetreffer-D98E3320-4C41-4527-BB3F-603D74692E53.png"
      />
      }

      <Footer />
    </div>
  );*/

  return (
    <div className="searchResults-container">
      {/* <Jumbotron title={<FormattedHTMLMessage id="searchResults.title" />} img={BackgroundImg} /> */}

      <div className="searchInfo"><h3><FormattedHTMLMessage id="searchResults.title" /></h3></div>

      <div className="inner-container">
        { !searchResults && 
        <div className="loading-container">
          <Loading /> <FormattedMessage id="searchResults.searching" />
        </div>
        }

        { searchResults && 
          <div className="row searchResults" style={{ width: '100%', margin:'auto'}}>
            <div className='result-disclaimer'>
              <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({id: "searchResults.resultDisclaimer"}) }}></p>
            </div>
            {searchResults.map(r =>
              <CardSearchResult
                searchResult={r}
              />
            )}
          </div>
          }

        <div className='searchBar-container'><SearchBar /></div>
        

        {false &&
          <img
            className="teaser-keine-treffer"
            src="https://anima-uploads.s3.amazonaws.com/projects/603e0debc9a76f12533011bf/releases/603ea21009ade11865ff0e28/img/suchergebnis-teaserkeinetreffer-D98E3320-4C41-4527-BB3F-603D74692E53.png"
          />
        }

        <Footer />
      </div>

      
    </div>
  );
}

export default App;
