import React, { useCallback, useContext, useState } from 'react';
import { useIntl } from 'react-intl'
import { Column, Filler, Row } from '../../components/base';
import Footer from '../../components/footer';
import styled from 'styled-components';

import "./style.scss";
import "./style2.scss";

import Button from '../../components/button';
import { useDispatch, useSelector } from 'react-redux';
import { REMOVE_PRODUCT } from '../../redux/shopping-cart-reducer';
import { NotificationContext } from '../../components/notification';
import { withRouter } from 'react-router-dom';
import ProductRow from '../../components/productrow';
import Jumbotron from '../../components/jumbotron';

import BackgroundImg from '../../assets/checkout.png'
import SearchBar from '../../components/searchbar';
import SectionHead from '../../components/sectionhead';
import FormattedHTMLMessage from '../../components/formattedhtmlmessage';
import { useHistoryLangQueryParam } from '../../components/langutils';

const NoProducts = styled.div`
  text-align: center;
  font-family: "PT Sans", Helvetica;
  color: rgba(0, 53, 95, 1);
`

export default function App() {
  const history = useHistoryLangQueryParam()
  const shoppingCart = useSelector(({ shoppingCart }) => shoppingCart)
  const dispatch = useDispatch()
  const { showNotification } = useContext(NotificationContext)
  const [deletingRows, setDeletingRows] = useState([])
  const intl = useIntl()

  const removeFromShoppingCart = useCallback(product => {
    setDeletingRows([...deletingRows, JSON.stringify(product)])
    setTimeout(() => {
      dispatch({ type: REMOVE_PRODUCT, product })
      const productName = `${product.productName} ${product.productMeta.address}`
      showNotification(intl.formatMessage({id: "shoppingCart.productRemovedFromCart.title" }), intl.formatMessage({id: "shoppingCart.productRemovedFromCart.text" }).replaceAll("[productName]", productName))  
    }, 1000)
  }, [shoppingCart, dispatch, showNotification, deletingRows, setDeletingRows, intl])

  /*return (
    <div className="suchergebnis">
      <Jumbotron title={<FormattedHTMLMessage id="shoppingCart.title" />} img={BackgroundImg} />
      <div style={{ minWidth: "80%" }}>
        <Column style={{width: '100%'}}>
          <SectionHead><FormattedHTMLMessage id="shoppingCart.header" /></SectionHead>

          {
            shoppingCart.products.length === 0 && <NoProducts><FormattedHTMLMessage id="shoppingCart.noProducts" /></NoProducts>
          }

          {
            shoppingCart.products.map(p => <ProductRow deleting={deletingRows.indexOf(JSON.stringify(p)) !== -1} key={JSON.stringify(p)} product={p} onDelete={removeFromShoppingCart} />)
          }

          <SectionHead></SectionHead>
          <Row><Filler /><Button label={<FormattedHTMLMessage id="shoppingCart.proceedToCheckout" />} onClick={() => history.push("/payment")} disabled={shoppingCart.products.length === 0} /></Row>
          

        </Column>
      </div>

      <SearchBar />

      <Footer />
    </div>
  );*/

  return (
    <div className="menaCart">
      <Jumbotron title={<FormattedHTMLMessage id="shoppingCart.title" />} img={BackgroundImg} />

      <div className="items-container">
        <SectionHead><FormattedHTMLMessage id="shoppingCart.header" /></SectionHead>

        {
          shoppingCart.products.length === 0 && <NoProducts><FormattedHTMLMessage id="shoppingCart.noProducts" /></NoProducts>
        }

        {
          shoppingCart.products.map(p => <ProductRow deleting={deletingRows.indexOf(JSON.stringify(p)) !== -1} key={JSON.stringify(p)} product={p} onDelete={removeFromShoppingCart} />)
        }

        <SectionHead></SectionHead>
        <Row><Filler /><Button label={<FormattedHTMLMessage id="shoppingCart.proceedToCheckout" />} onClick={() => history.push("/payment")} disabled={shoppingCart.products.length === 0} /></Row>
      </div>

      <SearchBar />

      <Footer />
    </div>
  );
}